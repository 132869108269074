import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	Select,
	Seperator,
	StyledForm,
	StyledInput,
	StyledTextArea,
	SubmitButton,
	SubmitButtonContainer,
} from '../../Containers/PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import { useOptimizedComponentWillMount } from '../../hooks'
import RadioGroup from '../RadioGroup'
import {
	nombreEtagesAtom,
	optionsPoidsLourdsAtom,
	optionsVehiculeLegerAtom,
	rippeurHoursAtom,
	rippeursAtom,
	temporisationAtom,
	typeCommandeAtom,
	typeEtageAtom,
	typeTransportAtom,
	dangerAtom,
} from '../../utils/price'
import { useForm, useWatch } from 'react-hook-form'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import { CustomInput } from '../../Containers/RegisterTransporter/style'
import InformationCourseACourse from '../../Containers/PasserCommande/InformationsCourseACourse'
import InformationsMiseADispo from '../../Containers/PasserCommande/InformationsMiseADispo'
import PoidsLours from '../../assets/img/poid_lourd@2x.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import tonnes7 from '../../assets/img/7.5.png'
import tonnes19 from '../../assets/img/tonnes19.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Break from '../../assets/img/CaptureBreak.png'
import Semi from '../../assets/img/semi.png'
import SemiImg from '../../assets/img/Capturesemi.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import tracteur from '../../assets/img/tracteur.png'
import { useAtom } from 'jotai'
import { useDispatch, useSelector } from 'react-redux'
import { setTarif } from '../../redux/tarif'
import PhoneInput from 'react-phone-input-2'
import { Controller } from 'react-hook-form'
import UploadFileCommande from '../UploadFileCommande/UploadFileCommande'

function CommandeForm({
	onSubmit,
	onError = () => {},
	loading = false,
	preloadForm = false,
	keepFilesAsObjects = false,
	preloadData,
	hideSubmit = false,
	disabled = false,
	submitButtonText = 'Payer',
	heur,
	setHeur,
	step,
	setStep,
	setIsStepOne,
	isStepOne,
	setPrice,
}) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		unregister,
		control,
		reset,
		getValues,
		formState: { errors, isValid, isDirty },
	} = useForm({
		mode: 'onChange',
	})
	const user = useSelector((state) => state.auth.user)

	const dispatch = useDispatch()
	const tarifMiseADispo = useSelector((state) => state.tarif.tarifMiseADispo)
	const tarifCourseACourse = useSelector(
		(state) => state.tarif.tarifCourseACourse
	)
	const type_transport = useRef()
	type_transport.current = watch('type_transport', '')
	const type_commande = useRef()
	type_commande.current = watch('type_commande', '')

	const livraison = useRef()
	livraison.current = watch('livraison', '')
	const nature_chargement = useRef()
	nature_chargement.current = watch(`nature_chargement`, '')

	const assurance = useRef()
	assurance.current = watch('assurance', '')

	const [typeCommande, setTypeCommande] = useAtom(typeCommandeAtom)
	const [typeTransport, setTypeTransport] = useAtom(typeTransportAtom)
	const [temporisation, setTemporisation] = useAtom(temporisationAtom)
	const [typeEtage, setTypeEtage] = useAtom(typeEtageAtom)
	const [danger, setDanger] = useAtom(dangerAtom)
	const [nombreEtages, setNombreEtages] = useAtom(nombreEtagesAtom)
	const [rippeurHours, setRippeurHours] = useAtom(rippeurHoursAtom)
	const [rippeurs, setRippeurs] = useAtom(rippeursAtom)
	const [isEtageDisabled, setIsEtageDisabled] = useState(false)
	const [isBreakDisabled, setIsBreakDisabled] = useState(false)
	const [isF12Disabled, setIsF12Disabled] = useState(false)
	const [isF14Disabled, setIsF14Disabled] = useState(false)
	const [isC20Disabled, setIsC20Disabled] = useState(false)
	const [isV75TDisabled, setIsV75TDisabled] = useState(false)
	const [is12TDisabled, setIs12TDisabled] = useState(false)
	const [is19TDisabled, setIs19TDisabled] = useState(false)
	const [isTracteurSemiDisabled, setIsTracteurSemiDisabled] = useState(false)

	const [optionsPoidsLourds, setOptionsPoidsLourds] = useAtom(
		optionsPoidsLourdsAtom
	)
	const [optionsVehiculeLeger, setOptionsVehiculeLeger] = useAtom(
		optionsVehiculeLegerAtom
	)

	useOptimizedComponentWillMount(() => {
		if (preloadForm) {
			reset(preloadData)
			type_commande.current = preloadData.type_commande
			type_transport.current = preloadData.type_transport
		}
	})

	const handleOptionChange = (e) => {
		const selectedOption = e.target.value

		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === 'Bachée' || selectedOption === 'Frigorifique') {
			// Désélectionner l'autre option si elle est sélectionnée
			const otherOption =
				selectedOption === 'Bachée' ? 'Frigorifique' : 'Bachée'
			const otherOptionCheckbox = document.querySelector(
				`[value="${otherOption}"]`
			)
			if (otherOptionCheckbox) {
				otherOptionCheckbox.checked = false
				console.log(
					'otherOptionCheckbox',
					otherOptionCheckbox.checked,
					otherOptionCheckbox.value
				)
			}
		}
	}

	const typeCommandeValue = useWatch({
		name: 'type_commande',
		defaultValue: null,
		control,
	})

	const type_transport_details = useWatch({
		name: 'type_transport_details',
		defaultValue: null,
		control,
	})

	const options_poids_lourds = useWatch({
		name: 'vehicule_poids_lourds',
		defaultValue: null,
		control,
	})

	const temporisationValue = useWatch({
		name: 'temporisation',
		defaultValue: null,
		control,
	})

	const typeEtageValue = useWatch({
		name: 'type_etage',
		defaultValue: null,
		control,
	})

	const nombreEtagesValue = useWatch({
		name: 'etage',
		defaultValue: null,
		control,
	})

	const rippeurHoursValue = useWatch({
		name: 'heure_jour_rippeur',
		defaultValue: null,
		control,
	})
	const dangerValue = useWatch({
		name: 'danger',
		defaultValue: null,
		control,
	})
	const nombrePalettes = useWatch({
		name: 'nombre_palettes',
		defaultValue: 0,
		control,
	})
	const rippeursValue = useWatch({
		name: 'rippeurs',
		defaultValue: null,
		control,
	})

	const optionsPoidsLourdsValue = useWatch({
		name: 'options_poids_lourds',
		defaultValue: [],
		control,
	})

	const optionsVehiculeLegerValue = useWatch({
		name: 'options_vehicule_leger',
		defaultValue: [],
		control,
	})

	useEffect(() => {
		if (typeCommandeValue) {
			setTypeCommande(typeCommandeValue)
		}
	}, [typeCommandeValue])

	useEffect(() => {
		if (type_transport_details) {
			setTypeTransport(type_transport_details)
		}
	}, [type_transport_details])
	useEffect(() => {
		if (dangerValue) {
			setDanger(dangerValue)
		}
	}, [dangerValue])
	useEffect(() => {
		if (options_poids_lourds && options_poids_lourds.length > 0) {
			setTypeTransport(options_poids_lourds)
		}
	}, [options_poids_lourds])
	useEffect(() => {
		if (
			type_transport_details &&
			type_transport.current === 'Véhicule léger'
		) {
			setTypeTransport(type_transport_details)
		}
		if (options_poids_lourds && type_transport.current === 'Poids lourds') {
			setTypeTransport(options_poids_lourds)
		}
	}, [type_transport.current])

	useEffect(() => {
		if (temporisationValue) setTemporisation(temporisationValue)
	}, [temporisationValue])

	useEffect(() => {
		if (typeEtageValue) {
			setTypeEtage(typeEtageValue)
		}
	}, [typeEtageValue])

	useEffect(() => {
		if (nombreEtagesValue !== undefined) {
			setNombreEtages(nombreEtagesValue)
		}
	}, [nombreEtagesValue])

	useEffect(() => {
		if (rippeurHoursValue !== undefined) {
			setRippeurHours(rippeurHoursValue)
		}
	}, [rippeurHoursValue])

	useEffect(() => {
		if (rippeursValue !== undefined) {
			setRippeurs(rippeursValue)
		}
	}, [rippeursValue])

	useEffect(() => {
		if (optionsPoidsLourdsValue) {
			setOptionsPoidsLourds(optionsPoidsLourdsValue)
		}
	}, [optionsPoidsLourdsValue])

	useEffect(() => {
		if (optionsVehiculeLegerValue) {
			setOptionsVehiculeLeger(optionsVehiculeLegerValue)
			console.log('optionsVehiculeLegerValue', optionsVehiculeLeger)
		}
	}, [optionsVehiculeLegerValue])
	const handleNext = (e) => {
		e.preventDefault()
		setStep(step + 1)
		setIsStepOne(true)
		const formSection = document.getElementById('form')

		if (formSection) {
			formSection.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handlePrevious = (e) => {
		e.preventDefault()
		setStep(step - 1)
		const formSection = document.getElementById('form')

		if (formSection) {
			formSection.scrollIntoView({ behavior: 'smooth' })
		}
	}

	// O
	useEffect(() => {
		if (watch(`nature_chargement`) === 'palette') {
			setIsBreakDisabled(false)
			setIsF12Disabled(false)
			setIsF14Disabled(false)
			setIsC20Disabled(false)
			setIsV75TDisabled(false)
			setIs12TDisabled(false)
			setIs19TDisabled(false)
			setIsTracteurSemiDisabled(false)
			const numPalettes = Number(watch('nombre_palettes'))
			setIsBreakDisabled(2 < numPalettes)
			setIsF12Disabled(5 < numPalettes)
			setIsF14Disabled(6 < numPalettes)
			setIsC20Disabled(7 < numPalettes)
			setIsV75TDisabled(16 < numPalettes)
			setIs12TDisabled(19 < numPalettes)
			setIs19TDisabled(22 < numPalettes)
			setIsTracteurSemiDisabled(32 < numPalettes)

			const transportMappings = [
				{
					threshold: 2,
					detail: 'Break',
					field: 'type_transport_details',
				},
				{
					threshold: 5,
					detail: 'Fourgon 12m³',
					field: 'type_transport_details',
				},
				{
					threshold: 6,
					detail: 'Fourgon 14m³',
					field: 'type_transport_details',
				},
				{
					threshold: 7,
					detail: 'Camion 20m³',
					field: 'type_transport_details',
				},
				{
					threshold: 16,
					detail: '7,5 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 19,
					detail: '12 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 22,
					detail: '19 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 32,
					detail: 'Tracteur+semi',
					field: 'vehicule_poids_lourds',
				},
			]

			transportMappings.forEach((mapping) => {
				if (
					numPalettes > mapping.threshold &&
					watch(`${mapping.field}`) === mapping.detail
				) {
					setValue(`${mapping.field}`, null)
				}
			})
		}
	}, [
		watch(`nature_chargement`),
		watch('nombre_palettes'),
		watch(`type_transport_details`),
		watch(`vehicule_poids_lourds`),
	])
	useEffect(() => {
		if (watch(`nature_chargement`) === 'vrac') {
			setIsBreakDisabled(false)
			setIsF12Disabled(false)
			setIsF14Disabled(false)
			setIsC20Disabled(false)
			setIsV75TDisabled(false)
			setIs12TDisabled(false)
			setIs19TDisabled(false)
			const numDimension = Number(watch(`nombre_dimension`))
			setIsBreakDisabled(6 < numDimension)
			setIsF12Disabled(12 < numDimension)
			setIsF14Disabled(14 < numDimension)
			setIsC20Disabled(20 < numDimension)
			setIsV75TDisabled(30 < numDimension)
			setIs12TDisabled(45 < numDimension)
			setIs19TDisabled(58 < numDimension)

			const transportMappings = [
				{
					threshold: 6,
					detail: 'Break',
					field: 'type_transport_details',
				},
				{
					threshold: 12,
					detail: 'Fourgon 12m³',
					field: 'type_transport_details',
				},
				{
					threshold: 14,
					detail: 'Fourgon 14m³',
					field: 'type_transport_details',
				},
				{
					threshold: 20,
					detail: 'Camion 20m³',
					field: 'type_transport_details',
				},
				{
					threshold: 30,
					detail: '7,5 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 45,
					detail: '12 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 58,
					detail: '19 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 86,
					detail: 'Semi',
					field: 'vehicule_poids_lourds',
				},
			]

			transportMappings.forEach((mapping) => {
				if (
					numDimension > mapping.threshold &&
					watch(`${mapping.field}`) === mapping.detail
				) {
					setTypeTransport(null)
					setValue(`${mapping.field}`, null)
				}
			})
		}
	}, [
		watch(`nature_chargement`),
		watch(`nombre_dimension`),
		watch(`type_transport_details`),
		watch(`vehicule_poids_lourds`),
	])
	useEffect(() => {
		console.log('typeTransport', typeTransport)
	}, [typeTransport])
	return (
		<StyledForm id='form' onSubmit={handleSubmit(onSubmit, onError)}>
			{step === 1 && (
				<div onSubmit={handleSubmit(onSubmit, onError)}>
					<FormContentSection>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<FormSectionTitle>
									Type de transport
								</FormSectionTitle>

								<svg
									xmlns='http://www.w3.org/2000/svg'
									style={{
										width: 20,
										height: 20,
										marginTop: 10,
										marginLeft: 10,
									}}
									fill='none'
									viewBox='0 0 24 24'
									stroke='#51C7F2'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
									/>
								</svg>
							</div>
						</div>
						<FormSectionCard>
							<RadioGroup
								name='type_commande'
								options={[
									//  { value: 'course-a-course', label: 'Course à course' },
									{
										value: 'mise-a-disposition',
										label: 'Mise à disposition',
									},
								]}
								register={register}
								required={true}
								disabled={disabled}
								errors={errors}
							/>
							{watch('type_commande') && (
								<RadioGroup
									name='temporisation'
									options={[
										{
											value: 'immediat',
											label: 'Immédiat',
										},
										{
											value: 'planifie',
											label: 'Planifié',
										},
									]}
									register={register}
									required={true}
									disabled={disabled}
									label='Type de temporisation'
									errors={errors}
								/>
							)}
						</FormSectionCard>
					</FormContentSection>

					{watch('type_commande') === 'mise-a-disposition' ? (
						<InformationsMiseADispo
							unregister={unregister}
							register={register}
							watch={watch}
							setValue={setValue}
							isStepOne={isStepOne}
							control={control}
							errors={errors}
							getValues={getValues}
							display={disabled}
							setHeur={setHeur}
							keepFilesAsObjects={
								preloadForm || keepFilesAsObjects
							}
							isActiverRippeur={user?.activerRippeur}
							setPrice={setPrice}
						/>
					) : (
						<InformationCourseACourse
							unregister={unregister}
							register={register}
							watch={watch}
							setValue={setValue}
							isStepOne={isStepOne}
							control={control}
							errors={errors}
							getValues={getValues}
							display={disabled}
							heur={heur}
							setHeur={setHeur}
							initialNumberOfCourses={preloadData?.courses.length}
							keepFilesAsObjects={
								preloadForm || keepFilesAsObjects
							}
						/>
					)}
					<button
						type='submit'
						style={{
							marginBottom: '1rem',
							border: 'none',
							alignSelf: 'flex-start',
							padding: '1rem 2rem',
							background: '#72c5ed',
							color: 'white',
							fontWeight: 'bold',
							cursor: 'pointer',
							borderRadius: 5,
							fontFamily: 'Montserrat',
							width: '20%',
							marginRight: '1rem',
							marginTop: '3rem',
						}}
					>
						Suivant
					</button>
				</div>
			)}
			{step === 2 && (
				<div onSubmit={handleSubmit(onSubmit, onError)}>
					<FormContentSection>
						<FormSectionTitle></FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Référence interne client
								</span>
								<StyledInput
									type='text'
									disabled={disabled}
									placeholder=''
									{...register('ref_client', {
										required: true,
									})}
								/>
								{errors.ref_client && (
									<div
										style={{
											color: 'red',
											marginTop: '0.5rem',
										}}
									>
										Veuillez saisir la référence interne du
										client.
									</div>
								)}
							</div>
						</FormSectionCard>
					</FormContentSection>
					{typeCommande === 'mise-a-disposition' && (
						<>
							<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '2.5rem',
											width: '50%',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Joindre Documents (PDF)
											</span>

											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												(Facultatif)
											</span>
										</div>

										<UploadFileCommande
											initialFiles={watch('files')}
											name='file'
											onChange={(files) => {
												if (keepFilesAsObjects) {
													setValue(
														`files`,
														files.map(
															(file) => file
														)
													)
												} else {
													setValue(
														`files`,
														files.map(
															(file) => file.id
														)
													)
												}
											}}
										/>
									</div>
								</FormSectionCard>
							</FormContentSection>
							<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '2rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '50%',
												marginRight: '1rem',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Nom du signataire{' '}
												<span style={{ color: 'red' }}>
													*
												</span>
											</span>
											<StyledInput
												type='text'
												disabled={disabled}
												placeholder=''
												{...register(`nom_signataire`, {
													required:
														'Veuillez indiquer le nom',
													minLength: {
														value: 3,
														message:
															'Le nom  doit contenir au moins 3 caractères.',
													},
													pattern: {
														value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
														message:
															'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
													},
												})}
											/>
											{errors.nom_signataire && (
												<div
													style={{
														color: 'red',
														marginTop: '0.5rem',
													}}
												>
													{
														errors.nom_signataire
															.message
													}
												</div>
											)}
										</div>

										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '50%',
												marginRight: '1rem',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Prénom du signataire{' '}
												<span style={{ color: 'red' }}>
													*
												</span>
											</span>
											<StyledInput
												type='text'
												disabled={disabled}
												placeholder=''
												{...register(
													`prénom_signataire`,
													{
														required:
															'Veuillez indiquer le prénom.',
														minLength: {
															value: 3,
															message:
																'Le prénom doit contenir au moins 3 caractères.',
														},
														pattern: {
															value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
															message:
																'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
														},
													}
												)}
											/>
											{errors.prénom_signataire && (
												<div
													style={{
														color: 'red',
														marginTop: '0.5rem',
													}}
												>
													{
														errors.prénom_signataire
															.message
													}
												</div>
											)}
										</div>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '2rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '50%',
												marginRight: '1rem',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Numéro de Téléphone 1 du
												signataire{' '}
												<span style={{ color: 'red' }}>
													*
												</span>
											</span>
											<Controller
												name='téléphone1_signataire'
												rules={{
													required:
														'Veuillez indiquer le numéro de téléphone .',
													pattern: {
														value: /^[0-9]+$/,
														message:
															'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
												}}
												control={control}
												render={({
													field: { onChange, value },
												}) => (
													<PhoneInput
														country='fr'
														placeholder=''
														value={value}
														onChange={onChange}
														inputStyle={{
															paddingRight:
																'1.5rem',
															paddingTop:
																'1.5rem',
															paddingBottom:
																'1.5rem',
															borderRadius:
																'0.5rem',
															border: 'none',
															fontFamily:
																'Montserrat',
															color: '#858ea8',
															flex: '1',
															display: 'flex',
															width: '100%',
														}}
														buttonStyle={{
															borderRadius:
																'0.5rem',
															border: 'none',
															fontFamily:
																'Montserrat',
															color: '#858ea8',
															flex: '1',
															display: 'flex',
														}}
													/>
												)}
											/>
											{errors.téléphone1_signataire && (
												<div
													style={{
														color: 'red',
														marginTop: '0.5rem',
													}}
												>
													{
														errors
															.téléphone1_signataire
															.message
													}
												</div>
											)}
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '50%',
												marginRight: '1rem',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Numéro de Téléphone 2 du
												signataire{' '}
												<span style={{ color: 'red' }}>
													*
												</span>
											</span>
											<Controller
												name='téléphone2_signataire'
												rules={{
													required:
														'Veuillez indiquer le numéro de téléphone .',
													pattern: {
														value: /^[0-9]+$/,
														message:
															'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
												}}
												control={control}
												render={({
													field: { onChange, value },
												}) => (
													<PhoneInput
														country='fr'
														placeholder=''
														value={value}
														onChange={onChange}
														inputStyle={{
															paddingRight:
																'1.5rem',
															paddingTop:
																'1.5rem',
															paddingBottom:
																'1.5rem',
															borderRadius:
																'0.5rem',
															border: 'none',
															fontFamily:
																'Montserrat',
															color: '#858ea8',
															flex: '1',
															display: 'flex',
															width: '100%',
														}}
														buttonStyle={{
															borderRadius:
																'0.5rem',
															border: 'none',
															fontFamily:
																'Montserrat',
															color: '#858ea8',
															flex: '1',
															display: 'flex',
														}}
													/>
												)}
											/>
											{errors.téléphone2_signataire && (
												<div
													style={{
														color: 'red',
														marginTop: '0.5rem',
													}}
												>
													{
														errors
															.téléphone2_signataire
															.message
													}
												</div>
											)}
										</div>
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											flexDirection: 'column',
											paddingBottom: '2rem',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Adresse email du signataire{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</span>
										<CustomInput
											type='text'
											{...register('email_signataire', {
												required:
													"Veuillez indiquer l'adresse e-mail du signataire.",
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message:
														'Veuillez entrer une adresse e-mail valide',
												},
											})}
										/>
										{errors.email_signataire && (
											<div
												style={{
													color: 'red',
													marginTop: '0.5rem',
													fontSize: '14px',
												}}
											>
												{
													errors.email_signataire
														.message
												}
											</div>
										)}
									</div>
								</FormSectionCard>
							</FormContentSection>
						</>
					)}
					{typeCommande === 'course-a-course' && (
						<>
							<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
									<p
										style={{
											color: 'black',
											//fontWeight: 'bold',
										}}
									>
										Y a t-il de la manutention chauffeur ?
									</p>

									<div
										className='radio-btn-group-palettes'
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '2rem',
										}}
									>
										<div
											className='radio'
											style={{
												marginRight: '1rem',
											}}
										>
											<input
												id='manutention_chauffeur-oui'
												type='radio'
												disabled={disabled}
												value='Oui'
												{...register(
													'manutention_chauffeur',
													{
														required: true,
													}
												)}
											/>

											<label htmlFor='manutention_chauffeur-oui'>
												Oui
											</label>
										</div>

										<div
											className='radio'
											style={{
												marginRight: '1rem',
											}}
										>
											<input
												id='manutention_chauffeur-non'
												type='radio'
												disabled={disabled}
												value='Non'
												{...register(
													'manutention_chauffeur',
													{
														required: true,
													}
												)}
											/>

											<label htmlFor='manutention_chauffeur-non'>
												Non
											</label>
										</div>
									</div>
									{errors.manutention_chauffeur && (
										<div
											style={{
												color: 'red',
												marginTop: '0.5rem',
											}}
										>
											Veuillez sélectionner si la
											manutention chauffeur est Oui ou
											Non.
										</div>
									)}
								</FormSectionCard>
							</FormContentSection>
							{user?.activerRippeur && (
								<FormContentSection>
									<FormSectionTitle>Rippeur</FormSectionTitle>
									<FormSectionCard>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'space-between',
												}}
											>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Nombre de ripper en plus du
													chauffeur
												</span>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													( Facultatif )
												</span>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
													marginBottom: '1rem',
												}}
											>
												<div>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='13.605'
														height='27.839'
														viewBox='0 0 13.605 27.839'
														style={{
															marginRight:
																'.5rem',
														}}
													>
														<g
															id='user'
															transform='translate(0.5 0.5)'
														>
															<path
																id='Path_11624'
																d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
																transform='translate(-25.195 -1.992)'
																fill='#51c7f2'
																stroke='#51c7f2'
																strokeWidth='1'
															/>
															<path
																id='Path_11625'
																d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
																transform='translate(-20.562 -14.552)'
																fill='#51c7f2'
																stroke='#51c7f2'
																strokeWidth='1'
															/>
															<path
																id='Path_11626'
																d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
																transform='translate(-30.081 -21.807)'
																fill='#51c7f2'
																stroke='#51c7f2'
																strokeWidth='1'
															/>
															<path
																id='Path_11627'
																d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
																transform='translate(-24.794 -21.807)'
																fill='#51c7f2'
																stroke='#51c7f2'
																strokeWidth='1'
															/>
														</g>
													</svg>
												</div>

												<CustomInput
													width={'100%'}
													type='number'
													disabled={disabled}
													min={0}
													{...register(
														'nombre_rippeur'
													)}
												/>
											</div>

											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'space-between',
												}}
											>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Nombre d'heure
												</span>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Select
													disabled={disabled}
													{...register(
														'heure_rippeur'
													)}
												>
													{Array.from(
														{ length: 12 },
														(v, i) => i + 1
													).map((i) => (
														<option
															key={String(i)}
															value={String(i)}
														>
															{i}h
														</option>
													))}
												</Select>
											</div>
										</div>
									</FormSectionCard>
								</FormContentSection>
							)}
							<FormContentSection>
								<FormSectionTitle>
									Lieu de livraison
								</FormSectionTitle>
								<FormSectionCard>
									<div
										className='radio-btn-group-palettes'
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '2rem',
										}}
									>
										<div
											className='radio'
											style={{
												marginRight: '1rem',
											}}
										>
											<input
												id='livraison-1'
												type='radio'
												disabled={disabled}
												value='Entrepot/Magasin/Chantier'
												{...register('livraison', {
													required: true,
												})}
											/>

											<label htmlFor='livraison-1'>
												Entrepot/Magasin/Chantier
											</label>
										</div>

										<div
											className='radio'
											style={{
												marginRight: '1rem',
											}}
										>
											<input
												id='livraison-2'
												type='radio'
												disabled={disabled}
												value='Domicile/Batiment/Maison'
												{...register('livraison', {
													required: true,
												})}
											/>

											<label htmlFor='livraison-2'>
												Domicile/Batiment/Maison
											</label>
										</div>
									</div>
									{errors.livraison && (
										<div
											style={{
												color: 'red',
												marginTop: '0.5rem',
											}}
										>
											Veuillez sélectionner le lieu de
											livraison.
										</div>
									)}
									<div>
										{livraison.current ===
											'Domicile/Batiment/Maison' && (
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent:
															'center',
														width: '30%',
														marginRight: '1rem',
													}}
												>
													<span
														style={{
															marginRight: '1rem',
															color: 'black',
															fontSize: 14,
														}}
													>
														Etage
													</span>

													<Select
														style={{
															width: '100%',
														}}
														disabled={disabled}
														{...register(`etage`, {
															required: true,
														})}
													>
														<option value='0'>
															0
														</option>
														<option
															value='1-10'
															disabled={
																isEtageDisabled
															}
														>
															1-10
														</option>
														<option
															value='11-20'
															disabled={
																isEtageDisabled
															}
														>
															11-20
														</option>
														<option
															value='21-30'
															disabled={
																isEtageDisabled
															}
														>
															21-30
														</option>
													</Select>
												</div>

												<div
													style={{
														//width: '25%',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<Controller
														name={`escalier`}
														control={control}
														rules={{
															required:
																!isEtageDisabled &&
																!getValues(
																	`assenceur`
																) &&
																!watch(
																	`etage]`
																) === '0',
														}}
														render={({ field }) => (
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																checked={
																	field.value ===
																	true
																}
																onChange={(e) =>
																	field.onChange(
																		e.target
																			.checked
																			? true
																			: false
																	)
																}
															/>
														)}
													/>
													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Escalier
													</span>
												</div>

												<div
													style={{
														//width: '25%',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<Controller
														name={`assenceur`}
														control={control}
														rules={{
															required:
																!isEtageDisabled &&
																!getValues(
																	`escalier`
																) &&
																!watch(
																	`etage]`
																) === '0',
														}}
														render={({ field }) => (
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																checked={
																	field.value ===
																	true
																}
																onChange={(e) =>
																	field.onChange(
																		e.target
																			.checked
																			? true
																			: false
																	)
																}
															/>
														)}
													/>

													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Assenceur
													</span>
												</div>
											</div>
										)}
									</div>
									{errors.etage && (
										<div
											style={{
												color: 'red',
												marginTop: '0.5rem',
											}}
										>
											Veuillez entrer le numéro d'étage.
										</div>
									)}
									{(errors.escalier || errors.assenceur) && (
										<div
											style={{
												color: 'red',
												marginTop: '0.5rem',
											}}
										>
											Veuillez sélectionner le type
											d'étage.
										</div>
									)}
								</FormSectionCard>
							</FormContentSection>

							{['chauffeur', 'transporter'].indexOf(
								user?.role
							) === -1 && (
								<FormContentSection>
									<FormSectionTitle>
										Assurance
									</FormSectionTitle>
									<FormSectionCard>
										<p
											style={{
												color: 'red',
												fontWeight: 'bold',
											}}
										>
											La plateforme prend en charge
											jusqu'a 100 000 euros de valeur
											marchandise.
										</p>

										<p
											style={{
												color: 'black',
												fontWeight: 'bold',
											}}
										>
											La valeur de votre chargement
											dépasse t'elle 100 000 euros?
										</p>

										<div
											className='radio-btn-group-palettes'
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '2rem',
											}}
										>
											<div
												className='radio'
												style={{
													marginRight: '1rem',
												}}
											>
												<input
													id='assurance-oui'
													type='radio'
													disabled={disabled}
													value='Oui'
													{...register('assurance', {
														required: true,
													})}
												/>

												<label htmlFor='assurance-oui'>
													Oui
												</label>
											</div>

											<div
												className='radio'
												style={{
													marginRight: '1rem',
												}}
											>
												<input
													id='assurance-non'
													type='radio'
													disabled={disabled}
													value='Non'
													{...register('assurance', {
														required: true,
													})}
												/>

												<label htmlFor='assurance-non'>
													Non
												</label>
											</div>
										</div>
										{errors.assurance && (
											<div
												style={{
													color: 'red',
													marginTop: '0.5rem',
												}}
											>
												Veuillez sélectionner une option
												pour l'assurance.
											</div>
										)}
										<div>
											{assurance.current === 'Oui' && (
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															alignItems:
																'center',
															justifyContent:
																'center',
															//width: '30%',
															marginRight: '1rem',
														}}
													>
														<span
															style={{
																color: 'black',
																fontWeight:
																	'bold',
																marginBottom:
																	'1rem',
															}}
														>
															Ci-dessous saisir la
															valeur du chargement
															afin que nous
															puissions avertir
															les assureurs
														</span>

														<div
															style={{
																position:
																	'relative',
																width: '100%',
															}}
														>
															<CustomInput
																width={'100%'}
																type='text'
																disabled={
																	disabled
																}
																{...register(
																	'assurance_amount',
																	{
																		validate:
																			(
																				value
																			) => {
																				const numericValue =
																					Number(
																						value.replace(
																							' ',
																							''
																						)
																					)
																				const onlyDigits =
																					/^\d+$/
																				return (
																					!isNaN(
																						numericValue
																					) &&
																					onlyDigits.test(
																						value
																					)
																				)
																			},
																		required: true,
																	}
																)}
															/>
															<span
																style={{
																	position:
																		'absolute',
																	right: '3rem',
																	top: '1rem',
																	color: 'red',
																}}
															>
																€
															</span>
														</div>
													</div>

													<p
														style={{
															color: 'black',
															fontWeight: 'bold',
														}}
													>
														Vous avez la possiblité
														de ne pas saisir la
														valur du chargment,
														cependant nous
														retiendrons la valeur de
														100 000 euros. Cela
														conformément au
														conditions d'utilisation
														de notre plateforme.
													</p>
													{errors.assurance_amount && (
														<div
															style={{
																color: 'red',
																marginTop:
																	'0.5rem',
															}}
														>
															Veuillez indiquer le
															montant de
															l\'assurance.
														</div>
													)}
												</div>
											)}
										</div>
									</FormSectionCard>
								</FormContentSection>
							)}
						</>
					)}

					<div
						style={{
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<button
							onClick={(e) => handlePrevious(e)}
							style={{
								marginBottom: '1rem',
								border: 'none',
								alignSelf: 'flex-start',
								padding: '1rem 2rem',
								background: '#72c5ed',
								color: 'white',
								fontWeight: 'bold',
								cursor: 'pointer',
								borderRadius: 5,
								width: '20%',
								fontFamily: 'Montserrat',
								marginRight: '1rem',
								marginTop: '3rem',
							}}
						>
							Précédent
						</button>
						<button
							type='submit'
							style={{
								marginBottom: '1rem',
								border: 'none',
								alignSelf: 'flex-start',
								padding: '1rem 2rem',
								background: '#72c5ed',
								color: 'white',
								fontWeight: 'bold',
								cursor: 'pointer',
								borderRadius: 5,
								fontFamily: 'Montserrat',
								width: '20%',
								marginRight: '1rem',
								marginTop: '3rem',
							}}
						>
							Suivant
						</button>
					</div>
				</div>
			)}
			{step === 3 && typeCommande === 'course-a-course' && (
				<>
					<FormContentSection>
						<FormSectionTitle>
							Nature du chargement
						</FormSectionTitle>
						<FormSectionCard>
							<div
								className='radio-btn-group-palettes'
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									className='radio'
									style={{
										marginRight: '1rem',
									}}
								>
									<input
										id={`nature-chargement-1`}
										type='radio'
										value='vrac'
										{...register(`nature_chargement`, {
											required: true,
										})}
									/>

									<label htmlFor={`nature-chargement-1`}>
										Vrac
									</label>
								</div>

								<div
									className='radio'
									style={{
										marginRight: '1rem',
									}}
								>
									<input
										id={`nature-chargement-2`}
										type='radio'
										value='palette'
										{...register(`nature_chargement`, {
											required: true,
										})}
									/>

									<label htmlFor={`nature-chargement-2`}>
										Palette
									</label>
								</div>
							</div>

							{errors && errors.nature_chargement && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez sélectionner la nature du
									chargement.
								</div>
							)}
							<div>
								{nature_chargement.current === 'palette' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '1.5rem',
												width: '100%',
											}}
										>
											<>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Nombre de palettes{' '}
													<span
														style={{ color: 'red' }}
													>
														*
													</span>
												</span>
												<CustomInput
													width={'100%'}
													type='number'
													min={0}
													{...register(
														`nombre_palettes`,
														{ required: true }
													)}
												/>
												{errors &&
													errors.nombre_palettes && (
														<div
															style={{
																color: 'red',
																marginTop:
																	'0.5rem',
															}}
														>
															Veuillez fournir le
															nombre de palettes.
														</div>
													)}
											</>
										</div>

										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												//marginBottom: '2.5rem',
												width: '100%',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Décrire la nature des palettes{' '}
												<span style={{ color: 'red' }}>
													*
												</span>
											</span>
											<StyledTextArea
												type='text'
												rows={4}
												placeholder=''
												{...register(
													`nature_palettes`,
													{ required: true }
												)}
											/>
											{errors &&
												errors.nature_palettes && (
													<div
														style={{
															color: 'red',
															marginTop: '0.5rem',
														}}
													>
														Veuillez fournir une
														description pour la
														nature du chargement.
													</div>
												)}
										</div>
									</div>
								)}
								{nature_chargement.current === 'vrac' && (
									<div
										style={{
											position: 'relative',
											width: '100%',
										}}
									>
										<CustomInput
											width={'100%'}
											type='number'
											min={0}
											required={true}
											{...register(`nombre_dimension`)}
										/>
										<span
											style={{
												position: 'absolute',
												right: '3rem',
												top: '1rem',
											}}
										>
											M³
										</span>
									</div>
								)}
							</div>
						</FormSectionCard>
					</FormContentSection>
					<FormContentSection>
						<FormSectionTitle>Détails Transport</FormSectionTitle>
						<p>
							<b>
								La plateforme prend en charge pour les poids
								lourds 30 minutes au chargement et 30 minutes au
								déchargement, au delà 0,41€ la minutes. 10 min
								pour les Véhicules légers.
							</b>
						</p>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '1rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Type de trasport
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '1rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												width: '15rem',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}
										>
											<input
												type='radio'
												disabled={disabled}
												style={{
													marginRight: '1rem',
												}}
												{...register('type_transport', {
													required: true,
												})}
												value={'Véhicule léger'}
											/>

											<span
												style={{
													marginRight: '0.5rem',
												}}
											>
												Véhicule léger
											</span>
											<img
												src={VehiculeLeger}
												style={{
													width: 40,
												}}
												alt=''
											/>
										</div>

										<div
											style={{
												width: '15rem',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}
										>
											<input
												style={{
													marginRight: '1rem',
												}}
												disabled={disabled}
												type='radio'
												{...register('type_transport', {
													required: true,
												})}
												value={'Poids lourds'}
											/>

											<span
												style={{
													marginRight: '0.5rem',
												}}
											>
												Poids lourds
											</span>

											<img
												src={PoidsLours}
												style={{
													width: 40,
												}}
												alt=''
											/>
										</div>
									</div>
								</div>
							</div>

							{type_transport.current && (
								<>
									<Seperator />

									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											//marginBottom: '1rem',
										}}
									>
										{type_transport.current !==
											'Poids lourds' && (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													width: '100%',
												}}
											>
												<div style={{ width: '100%' }}>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',

																display: 'flex',

																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={Break}
																	style={{
																		width: '100%',
																		height: 'auto', // Pour conserver les proportions de l'image
																		maxWidth:
																			'120px', // Limiter la largeur de l'image
																		maxHeight:
																			'80px', // Limiter la hauteur de l'image
																		objectFit:
																			'cover', // Ajuster la taille tout en conservant les proportions
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isBreakDisabled
																	}
																	{...register(
																		'type_transport_details',
																		{
																			required: true,
																		}
																	)}
																	value='Break'
																/>

																<span
																	style={{
																		marginRight:
																			'0.5rem',
																	}}
																>
																	Break
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontWeight:
																			'bold',
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		marginRight:
																			'0.5rem',
																	}}
																>
																	1097 kg
																</span>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 6M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	3750mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	1956mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	1320mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	1097kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 2
																	pal europe{' '}
																</span>
															</div>
														</div>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		Fourgon12
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isF12Disabled
																	}
																	{...register(
																		'type_transport_details',
																		{
																			required: true,
																		}
																	)}
																	value='Fourgon 12m³'
																/>

																<span
																	style={{
																		marginRight:
																			'0.5rem',
																	}}
																>
																	Fourgon 12m³
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		fontWeight:
																			'bold',
																	}}
																>
																	1244 kg
																</span>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 12M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	3030mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2070mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	1320mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	1244kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 5
																	pal europe
																</span>
															</div>
														</div>

														{/* ))} */}
													</div>
												</div>
												<div style={{ width: '100%' }}>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		Fourgon14
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isF14Disabled
																	}
																	{...register(
																		'type_transport_details',
																		{
																			required: true,
																		}
																	)}
																	value='Fourgon 14m³'
																/>

																<span
																	style={{
																		marginRight:
																			'0.5rem',
																	}}
																>
																	Fourgon 14m³
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		fontWeight:
																			'bold',
																		marginRight:
																			'0.5rem',
																	}}
																>
																	1353 kg
																</span>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 14M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	3680mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2070mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	1820mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	1353kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 6
																	pal europe
																</span>
															</div>
														</div>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		vehiculeCamion
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'110px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isC20Disabled
																	}
																	{...register(
																		'type_transport_details',
																		{
																			required: true,
																		}
																	)}
																	value='Camion 20m³'
																/>

																<span
																	style={{
																		marginRight:
																			'0.5rem',
																	}}
																>
																	Camion 20m³
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		fontWeight:
																			'bold',
																	}}
																>
																	1200 kg
																</span>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 20M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	4320mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2070mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	2100mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	1200kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 7
																	pal europe
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>

									{type_transport.current !==
										'Poids lourds' && (
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												//marginBottom: '2.5rem',
											}}
										>
											<span
												style={{
													marginBottom: '1rem',
													color: 'black',
													fontSize: 14,
												}}
											>
												Options Véhicule léger
											</span>

											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													marginBottom: '1rem',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														marginBottom: '1rem',
													}}
												>
													<div
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={disabled}
															{...register(
																'options_vehicule_leger',
																{
																	required: true,
																}
															)}
															value={
																'Frigorifique'
															}
															onChange={(e) => {
																const isChecked =
																	e.target
																		.checked
																const updatedOptions =
																	isChecked
																		? [
																				...optionsVehiculeLegerValue,
																				'Frigorifique',
																			]
																		: optionsVehiculeLegerValue.filter(
																				(
																					option
																				) =>
																					option !==
																					'Frigorifique'
																			)
																const bacheeIndex =
																	updatedOptions.indexOf(
																		'Bachée'
																	)
																if (
																	bacheeIndex !==
																	-1
																) {
																	updatedOptions.splice(
																		bacheeIndex,
																		1
																	)
																}
																setValue(
																	'options_vehicule_leger',
																	updatedOptions
																)
																handleOptionChange(
																	e
																)
															}}
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Frigorifique
														</span>
													</div>
													{type_transport_details ===
														'Camion 20m³' && (
														<div
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																{...register(
																	'options_vehicule_leger',
																	{
																		required: true,
																	}
																)}
																value={'Bachée'}
																onChange={(
																	e
																) => {
																	const isChecked =
																		e.target
																			.checked
																	const updatedOptions =
																		isChecked
																			? [
																					...optionsVehiculeLegerValue,
																					'Bachée',
																				]
																			: optionsVehiculeLegerValue.filter(
																					(
																						option
																					) =>
																						option !==
																						'Bachée'
																				)
																	const frigorifiqueIndex =
																		updatedOptions.indexOf(
																			'Frigorifique'
																		)

																	if (
																		frigorifiqueIndex !==
																		-1
																	) {
																		updatedOptions.splice(
																			frigorifiqueIndex,
																			1
																		)
																	}
																	setValue(
																		'options_vehicule_leger',
																		updatedOptions
																	)
																	handleOptionChange(
																		e
																	)
																}}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																Bachée
															</span>
														</div>
													)}
													<div
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={disabled}
															{...register(
																'options_vehicule_leger',
																{
																	required: true,
																}
															)}
															value={'Tall'}
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Tall
														</span>
													</div>

													<div
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={disabled}
															{...register(
																'options_vehicule_leger',
																{
																	required: true,
																}
															)}
															value={'Hayon'}
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Hayon
														</span>
													</div>
												</div>

												{type_transport.current ==
													null && <Seperator />}
											</div>
										</div>
									)}

									{type_transport.current !==
										'Véhicule léger' && (
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '100%',
											}}
										>
											<div style={{ width: '100%' }}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														marginBottom: '1rem',
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		tonnes7
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isV75TDisabled
																	}
																	{...register(
																		'vehicule_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value='7,5 Tonnes'
																/>

																<span>
																	7,5 Tonnes
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		fontWeight:
																			'bold',
																	}}
																>
																	2470 kg
																</span>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 30M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	6575mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2045mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	2400mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	2470kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 16
																	pal europe
																</span>
															</div>
														</div>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginTop: -5,
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		tonnes12
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		is12TDisabled
																	}
																	{...register(
																		'vehicule_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value='12 Tonnes'
																/>

																<span>
																	12 Tonnes
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		fontWeight:
																			'bold',
																	}}
																>
																	5600 kg
																</span>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 45M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	7540mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2480mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	2500mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	5600kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 19
																	pal europe
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div style={{ width: '100%' }}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		tonnes19
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		is19TDisabled
																	}
																	{...register(
																		'vehicule_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value='19 Tonnes'
																/>

																<span>
																	19 Tonnes
																</span>
															</div>
															<div
																style={{
																	marginLeft: 36,
																}}
															>
																<span
																	style={{
																		fontSize:
																			'14px',
																		marginRight:
																			'0.5rem',
																		fontWeight:
																			'bold',
																	}}
																>
																	9200 kg
																</span>
															</div>
															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Dimension en
																	M3 : 58M3{' '}
																</span>
																<span>
																	Longueur
																	utile :
																	9100mm{' '}
																</span>
																<span>
																	Largeur
																	utile :
																	2470mm{' '}
																</span>
																<span>
																	Hauteur
																	utile :
																	2490mm{' '}
																</span>
																<span>
																	Charge
																	utiles :
																	9200kg{' '}
																</span>
																<span>
																	Nombre de
																	palettes: 22
																	pal europe
																</span>
															</div>
														</div>

														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={Semi}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																	marginTop:
																		'1.2rem',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		isTracteurSemiDisabled
																	}
																	{...register(
																		'vehicule_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value='Tracteur+semi'
																/>

																<span>
																	Tracteur+semi
																</span>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<span>
																	Longueur
																	utile : 2.45
																	M
																</span>
																<span>
																	Largeur
																	utile : 1.50
																	M
																</span>
																<span>
																	Hauteur
																	utile : 1.60
																	M
																</span>
																<span>
																	Volume utile
																	: 6 M3
																</span>
																<span>
																	Charge max :
																	1000 kg
																</span>
																<span>
																	Nombre de
																	palettes: 32
																	pal europe
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div style={{ width: '100%' }}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														marginBottom: '1rem',
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
															width: '100%',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																width: '48%',
																marginRight:
																	'2%',
															}}
														>
															<div
																style={{
																	width: '100%',
																	height: 'auto',
																	marginBottom:
																		'0.5rem',
																}}
															>
																<img
																	src={
																		tracteurSemi
																	}
																	style={{
																		width: '100%',
																		height: 'auto',
																		maxWidth:
																			'120px',
																		maxHeight:
																			'80px',
																		objectFit:
																			'cover',
																	}}
																	alt=''
																/>
															</div>

															<div
																style={{
																	width: '50%',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='radio'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		disabled
																	}
																	{...register(
																		'vehicule_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value='Tracteur'
																/>

																<span>
																	Tracteur
																</span>
															</div>

															<div
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																	background:
																		'#f2f3f4',
																	padding: 10,
																	width: '70%',
																	borderRadius:
																		'5px',
																	marginTop:
																		'10px',
																	fontSize:
																		'13px',
																	lineHeight:
																		'1.5',
																}}
															>
																<div
																	style={{
																		display:
																			'flex',
																		alignItems:
																			'center',
																		justifyContent:
																			'flex-start',
																	}}
																>
																	<span>
																		Longueur
																		utile :{' '}
																	</span>
																	<span>
																		2.45 M
																	</span>
																</div>
																<div
																	style={{
																		display:
																			'flex',
																		alignItems:
																			'center',
																		justifyContent:
																			'flex-start',
																	}}
																>
																	<span>
																		Largeur
																		utile :{' '}
																	</span>
																	<span>
																		1.50 M
																	</span>
																</div>
																<div
																	style={{
																		display:
																			'flex',
																		alignItems:
																			'center',
																		justifyContent:
																			'flex-start',
																	}}
																>
																	<span>
																		Hauteur
																		utile :{' '}
																	</span>
																	<span>
																		1.60 M
																	</span>
																</div>
																<div
																	style={{
																		display:
																			'flex',
																		alignItems:
																			'center',
																		justifyContent:
																			'flex-start',
																	}}
																>
																	<span>
																		Volume
																		utile :{' '}
																	</span>
																	<span>
																		6 M3
																	</span>
																</div>
																<div
																	style={{
																		display:
																			'flex',
																		alignItems:
																			'center',
																		justifyContent:
																			'flex-start',
																	}}
																>
																	<span>
																		Charge
																		max :{' '}
																	</span>
																	<span>
																		1000 kg
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Options poids lourds
												</span>
												{/* <div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													//marginBottom: '1rem',
												}}
											>
												{[
													'Frigorifique',
													'Bachée',
													'Tall',
													'Hayon',
												].map((item, index) => (
													<div
														key={String(index)}
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={disabled}
															{...register(
																'options_poids_lourds'
															)}
															value={item}
														/>

														<span>{item}</span>
													</div>
												))}
											</div>
										</div> */}
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														marginBottom: '1rem',
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection:
																'row',
															marginBottom:
																'1rem',
														}}
													>
														<div
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																{...register(
																	'options_poids_lourds',
																	{
																		required: true,
																	}
																)}
																value={
																	'Frigorifique'
																}
																onChange={(
																	e
																) => {
																	const isChecked =
																		e.target
																			.checked
																	const updatedOptions =
																		isChecked
																			? [
																					...optionsPoidsLourdsValue,
																					'Frigorifique',
																				]
																			: optionsPoidsLourdsValue?.filter(
																					(
																						option
																					) =>
																						option !==
																						'Frigorifique'
																				)
																	const bacheeIndex =
																		updatedOptions.indexOf(
																			'Bachée'
																		)
																	if (
																		bacheeIndex !==
																		-1
																	) {
																		updatedOptions.splice(
																			bacheeIndex,
																			1
																		)
																	}
																	setValue(
																		'options_poids_lourds',
																		updatedOptions
																	)
																	handleOptionChange(
																		e
																	)
																}}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																Frigorifique
															</span>
														</div>
														{options_poids_lourds !==
															'Tracteur' && (
															<div
																style={{
																	width: '15rem',
																	display:
																		'flex',
																	alignItems:
																		'center',
																	justifyContent:
																		'flex-start',
																}}
															>
																<input
																	type='checkbox'
																	style={{
																		marginRight:
																			'1rem',
																	}}
																	disabled={
																		disabled
																	}
																	{...register(
																		'options_poids_lourds',
																		{
																			required: true,
																		}
																	)}
																	value={
																		'Bachée'
																	}
																	onChange={(
																		e
																	) => {
																		const isChecked =
																			e
																				.target
																				.checked
																		const updatedOptions =
																			isChecked
																				? [
																						...optionsPoidsLourdsValue,
																						'Bachée',
																					]
																				: optionsPoidsLourdsValue.filter(
																						(
																							option
																						) =>
																							option !==
																							'Bachée'
																					)
																		const frigorifiqueIndex =
																			updatedOptions.indexOf(
																				'Frigorifique'
																			)

																		if (
																			frigorifiqueIndex !==
																			-1
																		) {
																			updatedOptions.splice(
																				frigorifiqueIndex,
																				1
																			)
																		}
																		setValue(
																			'options_poids_lourds',
																			updatedOptions
																		)
																		handleOptionChange(
																			e
																		)
																	}}
																/>

																<span
																	style={{
																		marginRight:
																			'0.5rem',
																	}}
																>
																	Bachée
																</span>
															</div>
														)}
														<div
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																{...register(
																	'options_poids_lourds',
																	{
																		required: true,
																	}
																)}
																value={'Tall'}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																Tall
															</span>
														</div>

														<div
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={
																	disabled
																}
																{...register(
																	'options_poids_lourds',
																	{
																		//required: true,
																	}
																)}
																value={'Hayon'}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																Hayon
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</>
							)}
							{errors.type_transport && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez sélectionner le type de trasport .
								</div>
							)}
							{errors.type_transport_details && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez choisir le type de transport
									détaillé.
								</div>
							)}
							{errors.options_vehicule_leger && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez cocher au moins une option pour le
									type de transport.
								</div>
							)}
						</FormSectionCard>
					</FormContentSection>

					<FormContentSection>
						<FormSectionTitle></FormSectionTitle>
						<FormSectionCard>
							<p
								style={{
									color: 'black',
									//fontWeight: 'bold',
								}}
							>
								Y a-t-il un transport de matières dangereuses ?
							</p>

							<div
								className='radio-btn-group-palettes'
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									className='radio'
									style={{
										marginRight: '1rem',
									}}
								>
									<input
										id='danger-oui'
										type='radio'
										disabled={disabled}
										value='Oui'
										{...register('danger', {
											required: true,
										})}
									/>

									<label htmlFor='danger-oui'>Oui</label>
								</div>

								<div
									className='radio'
									style={{
										marginRight: '1rem',
									}}
								>
									<input
										id='danger-non'
										type='radio'
										disabled={disabled}
										value='Non'
										{...register('danger', {
											required: true,
										})}
									/>

									<label htmlFor='danger-non'>Non</label>
								</div>
							</div>
							{errors.danger && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez sélectionner si le transport de
									matières dangereuses est Oui ou Non.
								</div>
							)}
						</FormSectionCard>
					</FormContentSection>
				</>
			)}

			{step === 3 && (
				<div>
					<FormContentSection>
						<FormSectionTitle>
							Matériels supplémentaire
						</FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Matériels de manutention
								</span>

								<button
									type='button'
									style={{
										marginBottom: '1rem',
										border: 'none',
										alignSelf: 'flex-start',
										padding: '1rem 2rem',
										background: '#72c5ed',
										color: 'white',
										fontWeight: 'bold',
										cursor: 'pointer',
										borderRadius: 5,
										fontFamily: 'Montserrat',
									}}
									onClick={() =>
										setValue('manutention', [
											'Diable',
											'Transpalette manuel',
											'Sangle',
										])
									}
								>
									Tout
								</button>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										marginBottom: '1rem',
									}}
								>
									{[
										'Diable',
										'Transpalette manuel',
										'Sangle',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '33%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
												disabled={disabled}
												{...register('manutention', {
													required: true,
												})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.manutention && (
									<div
										style={{
											color: 'red',
											marginTop: '0.5rem',
											marginBottom: '0.5rem',
										}}
									>
										Veuillez sélectionner au moins un
										matériel de manutention.
									</div>
								)}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Epi
								</span>

								<button
									type='button'
									style={{
										marginBottom: '1rem',
										border: 'none',
										alignSelf: 'flex-start',
										padding: '1rem 2rem',
										background: '#72c5ed',
										color: 'white',
										fontWeight: 'bold',
										cursor: 'pointer',
										borderRadius: 5,
										fontFamily: 'Montserrat',
									}}
									onClick={() =>
										setValue('epi', [
											'Casque',
											'Gants',
											'Masque',
											'Lunettes',
											'Chaussure de sécurité',
											'Gilet de visibilité',
										])
									}
								>
									Tout
								</button>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										//marginBottom: '1rem',
									}}
								>
									{[
										'Casque',
										'Gants',
										'Masque',
										'Lunettes',
										'Chaussure de sécurité',
										'Gilet de visibilité',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '33%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
												disabled={disabled}
												{...register('epi', {
													required: true,
												})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.epi && (
									<div
										style={{
											color: 'red',
											marginTop: '0.5rem',
										}}
									>
										Veuillez sélectionner au moins un
										équipement de protection individuelle.
									</div>
								)}
							</div>

							{type_transport.current === 'Poids lourds' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Transpalette électrique
									</span>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											flexDirection: 'row',
											//marginBottom: '1rem',
										}}
									>
										<div
											style={{
												width: '33%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
												disabled={disabled}
												{...register(
													'transpalette_électrique',
													{}
												)}
												onChange={(e) => {
													setValue(
														'transpalette_électrique',
														e.target.checked
													)
												}}
											/>

											<span>Transpalette électrique</span>
										</div>
									</div>
								</div>
							)}
						</FormSectionCard>
					</FormContentSection>

					{!hideSubmit && (
						<>
							<div
								style={{
									width: '100%',
									margin: '2rem 0',
								}}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										//marginBottom: '1rem',
									}}
								>
									<input
										type='checkbox'
										style={{
											marginRight: '1rem',
										}}
										{...register('accept', {
											required: true,
										})}
										value={'accept'}
									/>

									<span>
										J'accepte les conditions de « La
										plateforme du transport » Lire les
										conditions générales
									</span>
								</div>
							</div>

							{user?.premium && (
								<div
									style={{
										width: '100%',
										textAlign: 'center',
										marginTop: '1rem',
									}}
								>
									<span
										style={{
											fontSize: 13,
										}}
									>
										Vous etes client{' '}
										<span
											style={{
												color: '#f1e5b0',
												fontWeight: 'bold',
											}}
										>
											Prémium
										</span>{' '}
										vous pouvez commandez vos transports et
										régler le total des transports
										mensuellement
									</span>
								</div>
							)}
							{!!user?.reduction && (
								<div
									style={{
										width: '100%',
										textAlign: 'center',
										marginTop: '1rem',
									}}
								>
									<span
										style={{
											width: '6rem',
											fontSize: 13,
											marginTop: '1rem',
										}}
									>
										En tant que client spécial nous vous
										appliquons une remise de{' '}
										{user.reduction}%.
									</span>
								</div>
							)}
							<SubmitButtonContainer>
								<SubmitButton type='submit' disabled={loading}>
									{loading ? 'Chargement' : submitButtonText}
								</SubmitButton>
							</SubmitButtonContainer>
						</>
					)}
					<button
						onClick={(e) => handlePrevious(e)}
						style={{
							marginBottom: '1rem',
							border: 'none',
							alignSelf: 'flex-start',
							padding: '1rem 2rem',
							background: '#72c5ed',
							color: 'white',
							fontWeight: 'bold',
							cursor: 'pointer',
							borderRadius: 5,
							width: '20%',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
							marginTop: '3rem',
						}}
					>
						Précédent
					</button>
				</div>
			)}
			<div
				style={{
					marginTop: '1rem',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				{step < 0 && (
					<button
						onClick={(e) => handlePrevious(e)}
						style={{
							marginBottom: '1rem',
							border: 'none',
							alignSelf: 'flex-start',
							padding: '1rem 2rem',
							background: '#72c5ed',
							color: 'white',
							fontWeight: 'bold',
							cursor: 'pointer',
							borderRadius: 5,
							width: '20%',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						Précédent
					</button>
				)}
				{step < 0 && step < 0 && (
					<button
						style={{
							marginBottom: '1rem',
							border: 'none',
							alignSelf: 'flex-start',
							padding: '1rem 2rem',
							background: '#72c5ed',
							color: 'white',
							fontWeight: 'bold',
							cursor: 'pointer',
							borderRadius: 5,
							fontFamily: 'Montserrat',
							width: '20%',
							marginRight: '1rem',
						}}
						type='button'
						onClick={(e) => handleNext(e)}
					>
						Suivant
					</button>
				)}
			</div>
		</StyledForm>
	)
}

export default React.memo(CommandeForm)

