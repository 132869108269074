import { Select, Seperator, StyledTextArea } from './PasserCommande.styles'
import { Controller } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import React, { useEffect, useState, useRef } from 'react'
import 'react-phone-input-2/lib/style.css'
import moment from 'moment'
import AutocompleteAddress from '../../Components/GooglePlacesAutoComplete/AutocompleteAddress'
import { CustomInput } from '../RegisterTransporter/style'
import PoidsLours from '../../assets/img/poid_lourd@2x.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import tonnes7 from '../../assets/img/7.5.png'
import tonnes19 from '../../assets/img/tonnes19.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Break from '../../assets/img/CaptureBreak.png'
import Semi from '../../assets/img/semi.png'
import SemiImg from '../../assets/img/Capturesemi.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import { useWatch } from 'react-hook-form'
import UploadFileCommande from '../../Components/UploadFileCommande/UploadFileCommande'
import { computePriceMiseADispo, temporisationAtom } from '../../utils/price'
import { useSelector } from 'react-redux'
import { useAtom } from 'jotai'

function MissionsMiseADispo({
	collapsedItems,
	keepFilesAsObjects,
	i,
	watch,
	register,
	getValues,
	setValue,
	control,
	display,
	distanceVector,
	setDistanceVector,
	priceVector,
	setPriceVector,
	errors,
	setHeur,
	isActiverRippeur,
}) {
	const tarif = useSelector((state) => state.tarif.tarif)
	const livraison = useRef()
	livraison.current = watch(`missions[${i}].livraison`, '')
	const manutentionChauffeur = useRef()
	manutentionChauffeur.current = watch(
		`missions[${i}].manutention_chauffeur`,
		''
	)
	const type_transport = useRef()
	type_transport.current = watch(`missions[${i}].type_transport`, '')
	const nature_chargement = useRef()
	nature_chargement.current = watch(`missions[${i}].nature_chargement`, '')
	const assurance = useRef()
	assurance.current = watch(`missions[${i}].assurance`, '')
	const [temporisation, setTemporisation] = useAtom(temporisationAtom)

	const optionsPoidsLourdsValue = useWatch({
		name: `missions[${i}].options_poids_lourds`,
		defaultValue: [],
		control,
	})
	const optionsVehiculeLegerValue = useWatch({
		name: `missions[${i}].options_vehicule_leger`,
		defaultValue: [],
		control,
	})
	const type_transport_details_poids_lourds = useWatch({
		name: `missions[${i}].vehicule_poids_lourds`,
		defaultValue: null,
		control,
	})
	const type_transport_details = useWatch({
		name: `missions[${i}].type_transport_details`,
		defaultValue: null,
		control,
	})

	const [showInfoHeureFin, setShowInfoHeureFin] = useState(false)
	const [showInfoDateFin, setShowInfoDateFin] = useState(false)
	const [typeTransport, setTypeTransport] = useState(null)
	const [optionsPoidsLourds, setOptionsPoidsLourds] = useState([])
	const [optionsVehiculeLeger, setOptionsVehiculeLeger] = useState([])
	const [isBreakDisabled, setIsBreakDisabled] = useState(false)
	const [isF12Disabled, setIsF12Disabled] = useState(false)
	const [isF14Disabled, setIsF14Disabled] = useState(false)
	const [isC20Disabled, setIsC20Disabled] = useState(false)
	const [isV75TDisabled, setIsV75TDisabled] = useState(false)
	const [is12TDisabled, setIs12TDisabled] = useState(false)
	const [is19TDisabled, setIs19TDisabled] = useState(false)
	const [isTracteurSemiDisabled, setIsTracteurSemiDisabled] = useState(false)
	const [semi, setSemi] = useState(false)
	const nombrePalettes = useRef()
	nombrePalettes.current = watch(`missions[${i}].nombre_palettes`, 0)
	const [isEtageDisabled, setIsEtageDisabled] = useState(false)

	const calculateEndDate = (startDate, startTime, endTime) => {
		const [startHour, startMinute] = startTime?.split(':').map(Number)
		const [endHour, endMinute] = endTime?.split(':').map(Number)
		const startTimeInMinutes = startHour * 60 + startMinute
		const endTimeInMinutes = endHour * 60 + endMinute
		let endDate = moment(startDate, 'DD/MM/YYYY')
		if (endTimeInMinutes <= startTimeInMinutes) {
			endDate = endDate.add(1, 'day')
		}

		return endDate.format('DD/MM/YYYY')
	}

	const filterEndTimeOptions = (startTime = '00:00', startDate) => {
		const [startHour, startMinute] = startTime?.split(':').map(Number)
		const startTimeInMinutes = startHour * 60 + startMinute

		const minEndTimeInMinutes = startTimeInMinutes + 7 * 60 // 7 heures après l'heure de début
		const maxEndTimeInMinutes = startTimeInMinutes + 12 * 60 // 12 heures après l'heure de début

		const options = []
		for (
			let minutes = minEndTimeInMinutes;
			minutes <= maxEndTimeInMinutes;
			minutes += 30
		) {
			const hours = Math.floor(minutes / 60) % 24
			const mins = minutes % 60
			const endTime = `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
			const endDate = calculateEndDate(startDate, startTime, endTime)

			if (startDate) {
				options.push({
					label: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}         (${endDate})`,
					value: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
				})
			} else {
				options.push({
					label: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
					value: `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`,
				})
			}
		}

		return options
	}
	const generateTimeOptions = (startDate, temporisation) => {
		const options = []
		const currentDate = new Date()
		const currentHour = currentDate.getHours()
		const currentMinute = currentDate.getMinutes()
		const currentDatePlus24Hours = moment()
			.add(24, 'hours')
			.format('DD/MM/YYYY')

		// Vérifier si la date de début est égale à la date actuelle
		if (
			startDate &&
			moment(startDate).format('DD/MM/YYYY') ===
				moment(currentDate).format('DD/MM/YYYY') &&
			temporisation === 'immediat'
		) {
			let startHour = currentHour + 2 // Commencer 2 heures après l'heure actuelle
			let startMinute = 0
			if (currentMinute > 0 && currentMinute <= 30) {
				startMinute = 30
			} else if (currentMinute > 30) {
				startMinute = 0
				startHour++
			}

			// Boucle pour générer les options de temps à partir de l'heure de début ajustée
			for (let hours = startHour; hours < 24; hours++) {
				for (
					let minutes = hours === startHour ? startMinute : 0;
					minutes < 60;
					minutes += 30
				) {
					const hourText = hours.toString().padStart(2, '0')
					const minuteText = minutes.toString().padStart(2, '0')
					const time = `${hourText}:${minuteText}`
					options.push({ label: time, value: time })
				}
			}
		} else if (
			moment(startDate).format('DD/MM/YYYY') === currentDatePlus24Hours &&
			temporisation === 'planifie'
		) {
			// Extraire uniquement les heures et les minutes
			const timePart = moment().add(24, 'hours').format('HH:mm')
			let [currentHour, currentMinute] = timePart.split(':').map(Number)

			if (currentMinute > 0 && currentMinute <= 30) {
				currentMinute = 30
			} else if (currentMinute > 30) {
				currentMinute = 0
				currentHour++
			}

			// Boucle pour générer les options de temps à partir de l'heure de début ajustée
			for (let hours = currentHour; hours < 24; hours++) {
				for (
					let minutes = hours === currentHour ? currentMinute : 0;
					minutes < 60;
					minutes += 30
				) {
					const hourText = hours.toString().padStart(2, '0')
					const minuteText = minutes.toString().padStart(2, '0')
					const time = `${hourText}:${minuteText}`
					options.push({ label: time, value: time })
				}
			}
		} else {
			// Utiliser la logique d'origine si la date de début n'est pas égale à la date actuelle
			for (let hours = 0; hours < 24; hours++) {
				for (let minutes = 0; minutes < 60; minutes += 30) {
					const hourText = hours.toString().padStart(2, '0')
					const minuteText = minutes.toString().padStart(2, '0')
					const time = `${hourText}:${minuteText}`
					options.push({ label: time, value: time })
				}
			}
		}

		return options
	}
	const handleDateDebutChange = (date_debut) => {
		if (temporisation === 'planifie') {
			const currentDatePlus24Hours = moment()
				.add(24, 'hours')
				.format('DD/MM/YYYY')

			if (
				moment(date_debut, 'DD/MM/YYYY').isBefore(
					moment(currentDatePlus24Hours, 'DD/MM/YYYY')
				)
			) {
				date_debut = moment(
					currentDatePlus24Hours,
					'DD/MM/YYYY'
				).toDate()
			}
		}
		let heure_debut = '00:00'
		const options = generateTimeOptions(date_debut, temporisation)
		if (options.length > 0) {
			heure_debut = options[0].value
			setValue(`missions[${i}].heure_debut`, options[0].value)
		}
		return { date_debut, heure_debut }
	}

	const handleOptionChange = (e) => {
		const selectedOption = e.target.value

		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === 'Bachée' || selectedOption === 'Frigorifique') {
			// Désélectionner l'autre option si elle est sélectionnée
			const otherOption =
				selectedOption === 'Bachée' ? 'Frigorifique' : 'Bachée'
			const otherOptionCheckbox = document.querySelector(
				`[value="${otherOption}"]`
			)
			if (otherOptionCheckbox) {
				otherOptionCheckbox.checked = false
			}
		}
	}
	function calculerNombreHeures(dateDebut, heureDebut, dateFin, heureFin) {
		const dateTimeDebut = `${moment(dateDebut).format('DD/MM/YYYY')} ${heureDebut}`
		const dateTimeFin = `${dateFin} ${heureFin}`

		const debut = moment(dateTimeDebut, 'DD/MM/YYYY HH:mm')
		const fin = moment(dateTimeFin, 'DD/MM/YYYY HH:mm')

		const totalMinutes = fin.diff(debut, 'minutes')

		// Convertissez les minutes en heures et minutes
		const heures = Math.floor(totalMinutes / 60)
		const minutes = totalMinutes % 60

		// Formatez les résultats en "HH:mm"
		return `${heures}:${minutes < 10 ? '0' : ''}${minutes}`
	}
	useEffect(() => {
		if (
			watch(`missions[${i}].date_debut`) &&
			watch(`missions[${i}].date_fin`) &&
			watch(`missions[${i}].heure_debut`) &&
			watch(`missions[${i}].heure_fin`)
		) {
			const resulta = calculerNombreHeures(
				watch(`missions[${i}].date_debut`),
				watch(`missions[${i}].heure_debut`),
				watch(`missions[${i}].date_fin`),
				watch(`missions[${i}].heure_fin`)
			)
			console.log('resulta', resulta)
			setValue(`missions[${i}].heure_rippeur`, resulta)
		}
	}, [
		watch(`missions[${i}].date_debut`),
		watch(`missions[${i}].date_fin`),
		watch(`missions[${i}].heure_fin`),
		watch(`missions[${i}].heure_debut`),
	])
	useEffect(() => {
		const isNonChauffeurHandling = watch(
			`missions[${i}].manutention_chauffeur`
		)
		const nombreRippeur = watch(`missions[${i}].nombre_rippeur`)
		if (isActiverRippeur) {
			setIsEtageDisabled(isNonChauffeurHandling && nombreRippeur <= 0)
			if (isNonChauffeurHandling && nombreRippeur <= 0) {
				setValue(`missions[${i}].etage`, '0')
			}
		} else {
			setIsEtageDisabled(isNonChauffeurHandling)
			if (isNonChauffeurHandling) {
				setValue(`missions[${i}].etage`, '0')
			}
		}

		if (isNonChauffeurHandling === 'true' || nombreRippeur > 0) {
			setIsEtageDisabled(false)
		}
	}, [
		watch(`missions[${i}].manutention_chauffeur`),
		watch(`missions[${i}].nombre_rippeur`),
	])

	// Type de transport switching
	useEffect(() => {
		if (watch(`missions[${i}].type_transport_details`)) {
			setTypeTransport(watch(`missions[${i}].type_transport_details`))
		}
	}, [watch(`missions[${i}].type_transport_details`)])

	useEffect(() => {
		if (watch(`missions[${i}].vehicule_poids_lourds`)) {
			setTypeTransport(watch(`missions[${i}].vehicule_poids_lourds`))
		}
	}, [watch(`missions[${i}].vehicule_poids_lourds`)])

	useEffect(() => {
		if (type_transport.current === 'Véhicule léger') {
			setValue(`missions[${i}].vehicule_poids_lourds`, undefined, {
				shouldValidate: true,
			})
			setValue(`missions[${i}].options_poids_lourds`, [], {
				shouldValidate: true,
			})
			setTypeTransport(type_transport_details)
		}
		if (type_transport.current === 'Poids lourds') {
			setValue(`missions[${i}].type_transport_details`, undefined, {
				shouldValidate: true,
			})
			setValue(`missions[${i}].options_vehicule_leger`, [], {
				shouldValidate: true,
			})
			setTypeTransport(type_transport_details_poids_lourds)
		}
	}, [type_transport.current])

	// Options switching
	useEffect(() => {
		if (watch(`missions[${i}].options_poids_lourds`)) {
			setOptionsPoidsLourds(watch(`missions[${i}].options_poids_lourds`))
		}
	}, [watch(`missions[${i}].options_poids_lourds`)])
	useEffect(() => {
		if (watch(`missions[${i}].options_vehicule_leger`)) {
			setOptionsVehiculeLeger(
				watch(`missions[${i}].options_vehicule_leger`)
			)
		}
	}, [watch(`missions[${i}].options_vehicule_leger`)])

	useEffect(() => {
		if (watch(`missions[${i}].nature_chargement`) === 'palette') {
			setIsBreakDisabled(false)
			setIsF12Disabled(false)
			setIsF14Disabled(false)
			setIsC20Disabled(false)
			setIsV75TDisabled(false)
			setIs12TDisabled(false)
			setIs19TDisabled(false)
			setSemi(false)
			setIsTracteurSemiDisabled(false)
			const numPalettes = Number(nombrePalettes.current)
			setIsBreakDisabled(2 < numPalettes)
			setIsF12Disabled(5 < numPalettes)
			setIsF14Disabled(6 < numPalettes)
			setIsC20Disabled(7 < numPalettes)
			setIsV75TDisabled(16 < numPalettes)
			setIs12TDisabled(19 < numPalettes)
			setIs19TDisabled(22 < numPalettes)
			setSemi(33 < numPalettes)
			setIsTracteurSemiDisabled(32 < numPalettes)

			const transportMappings = [
				{
					threshold: 2,
					detail: 'Break',
					field: 'type_transport_details',
				},
				{
					threshold: 5,
					detail: 'Fourgon 12m³',
					field: 'type_transport_details',
				},
				{
					threshold: 6,
					detail: 'Fourgon 14m³',
					field: 'type_transport_details',
				},
				{
					threshold: 7,
					detail: 'Camion 20m³',
					field: 'type_transport_details',
				},
				{
					threshold: 16,
					detail: '7,5 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 19,
					detail: '12 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 22,
					detail: '19 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 33,
					detail: 'Semi',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 32,
					detail: 'Tracteur+semi',
					field: 'vehicule_poids_lourds',
				},
			]

			transportMappings.forEach((mapping) => {
				if (
					numPalettes > mapping.threshold &&
					watch(`missions[${i}].${mapping.field}`) === mapping.detail
				) {
					setTypeTransport(null)
					setValue(`missions[${i}].${mapping.field}`, null)
				}
			})
		}
	}, [
		watch(`missions[${i}].nature_chargement`),
		nombrePalettes.current,
		watch(`missions[${i}].type_transport_details`),
		watch(`missions[${i}].vehicule_poids_lourds`),
	])
	useEffect(() => {
		if (watch(`missions[${i}].nature_chargement`) === 'vrac') {
			setIsBreakDisabled(false)
			setIsF12Disabled(false)
			setIsF14Disabled(false)
			setIsC20Disabled(false)
			setIsV75TDisabled(false)
			setIs12TDisabled(false)
			setIs19TDisabled(false)
			setSemi(false)
			const numDimension = Number(
				watch(`missions[${i}].nombre_dimension`)
			)
			setIsBreakDisabled(6 < numDimension)
			setIsF12Disabled(12 < numDimension)
			setIsF14Disabled(14 < numDimension)
			setIsC20Disabled(20 < numDimension)
			setIsV75TDisabled(30 < numDimension)
			setIs12TDisabled(45 < numDimension)
			setIs19TDisabled(58 < numDimension)
			setSemi(86 < numDimension)

			const transportMappings = [
				{
					threshold: 6,
					detail: 'Break',
					field: 'type_transport_details',
				},
				{
					threshold: 12,
					detail: 'Fourgon 12m³',
					field: 'type_transport_details',
				},
				{
					threshold: 14,
					detail: 'Fourgon 14m³',
					field: 'type_transport_details',
				},
				{
					threshold: 20,
					detail: 'Camion 20m³',
					field: 'type_transport_details',
				},
				{
					threshold: 30,
					detail: '7,5 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 45,
					detail: '12 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 58,
					detail: '19 Tonnes',
					field: 'vehicule_poids_lourds',
				},
				{
					threshold: 86,
					detail: 'Semi',
					field: 'vehicule_poids_lourds',
				},
			]

			transportMappings.forEach((mapping) => {
				if (
					numDimension > mapping.threshold &&
					watch(`missions[${i}].${mapping.field}`) === mapping.detail
				) {
					setTypeTransport(null)
					setValue(`missions[${i}].${mapping.field}`, null)
				}
			})
		}
	}, [
		watch(`missions[${i}].nature_chargement`),
		watch(`missions[${i}].nombre_dimension`),
		watch(`missions[${i}].type_transport_details`),
		watch(`missions[${i}].vehicule_poids_lourds`),
	])
	useEffect(() => {
		if (
			watch(`missions[${i}].heure_debut`) &&
			watch(`missions[${i}].heure_fin`) &&
			watch(`missions[${i}].kilometrage`) &&
			(optionsVehiculeLeger || optionsPoidsLourds)
		) {
			const startDate = watch(`missions[${i}].date_debut`)
			const startTime = watch(`missions[${i}].heure_debut`)
			const endDate = watch(`missions[${i}].date_fin`)
			const endTime = watch(`missions[${i}].heure_fin`)
			const danger = watch(`missions[${i}].danger`)

			// Convertir startDate en format 'YYYY-MM-DD'
			const startDateFormatted = moment(startDate).format('YYYY-MM-DD')
			// Déterminer le format de endDate
			const endDateFormat = endDate.includes('/')
				? 'DD/MM/YYYY'
				: 'YYYY-MM-DD'

			// Combinez les dates et les heures
			const startDateTime = `${startDateFormatted} ${startTime}`
			const endDateTime = `${endDate} ${endTime}`

			// Créez des objets moment
			const startMoment = moment(startDateTime, 'YYYY-MM-DD HH:mm')
			const endMoment = moment(endDateTime, `${endDateFormat} HH:mm`)

			// Calculez la différence en heures
			const heures = endMoment.diff(startMoment, 'hours', true)
			const kilo_jour = watch(`missions[${i}].kilometrage`)
			const heureRippeur = watch(`missions[${i}].heure_rippeur`)
			const nbrRippeur = watch(`missions[${i}].nombre_rippeur`)

			let updatedMission = { heures, kilo_jour, heureRippeur, nbrRippeur }

			if (
				watch(`missions[${i}].livraison`) === 'Domicile/Batiment/Maison'
			) {
				const nombreEtage = watch(`missions[${i}].etage`)
				const typeEtage = watch(`missions[${i}.type_etage]`)
				updatedMission = { ...updatedMission, nombreEtage, typeEtage }
			}

			updatedMission = { ...updatedMission, typeTransport: typeTransport }

			if (optionsVehiculeLeger) {
				updatedMission = {
					...updatedMission,
					optionsVehiculeLeger: optionsVehiculeLeger,
				}
			}
			if (optionsPoidsLourds) {
				updatedMission = {
					...updatedMission,
					optionsPoidsLourds: optionsPoidsLourds,
				}
			}
			if (danger) {
				updatedMission = { ...updatedMission, danger: danger }
			}
			if (!watch('type_commande')) return
			if (!kilo_jour) return
			if (!heures) return
			const prix = computePriceMiseADispo(tarif, updatedMission)

			setValue(`missions[${i}].prix`, prix)
		}
	}, [
		watch(`missions[${i}].heure_debut`),
		watch(`missions[${i}].heure_fin`),
		watch(`missions[${i}].kilometrage`),
		watch(`missions[${i}].heure_rippeur`),
		watch(`missions[${i}].nombre_rippeur`),
		watch(`missions[${i}].livraison`),
		watch(`missions[${i}].etage`),
		watch(`missions[${i}].type_etage`),
		typeTransport,
		optionsVehiculeLeger,
		optionsPoidsLourds,
		watch(`missions[${i}].danger`),
	])

	useEffect(() => {
		if (watch(`missions[${i}].kilometrage`)) {
			setDistanceVector(
				new Map(
					distanceVector.set(
						`${i}`,
						watch(`missions[${i}].kilometrage`) * 1000
					)
				)
			)
		}
	}, [])
	useEffect(() => {
		setPriceVector(
			new Map(priceVector.set(`${i}`, watch(`missions[${i}].prix`)))
		)
	}, [watch(`missions[${i}].prix`)])
	useEffect(() => {
		console.log('isEtageDisabled', !isEtageDisabled)
		console.log(
			'getValues("missions[i].assenceur")',
			!getValues('missions[i].assenceur')
		)
		console.log(watch('missions[i].etage') !== 0)
		console.log(
			!isEtageDisabled &&
				getValues(`missions[${i}].assenceur`) &&
				watch(`missons[${i}.etage]`) === '0'
		)
	}, [isEtageDisabled])
	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Adresse début <span style={{ color: 'red' }}>*</span>
					</span>

					<Controller
						name={`missions[${i}].adresse_debut`}
						rules={{ required: true }}
						control={control}
						render={({ field: { onChange, value } }) => (
							<AutocompleteAddress
								onPlaceSelect={(placeDetails) => {
									console.log(
										'Place ID:',
										placeDetails.place_id
									)
									console.log(
										'Description:',
										placeDetails.description
									)
								}}
								onValueChange={async (selectedValue) => {
									if (selectedValue) {
										onChange(selectedValue)
									}
								}}
								value={value}
							/>
						)}
					/>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].adresse_debut && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez remplir l'adresse début mise à
								disposition
							</div>
						)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Adresse de fin{' '}
							<span style={{ color: 'red' }}>*</span>
						</span>
					</div>

					<Controller
						name={`missions[${i}].adresse_fin`}
						rules={{ required: true }}
						control={control}
						render={({ field: { onChange, value } }) => (
							<AutocompleteAddress
								value={value}
								onPlaceSelect={(placeDetails) => {
									console.log(
										'Place ID:',
										placeDetails.place_id
									)
									console.log(
										'Description:',
										placeDetails.description
									)
								}}
								onValueChange={async (selectedValue) => {
									if (selectedValue) {
										onChange(selectedValue)
									}
								}}
							/>
						)}
					/>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].adresse_fin && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez remplir l'adresse fin mise à
								disposition
							</div>
						)}
				</div>
			</div>
			<div
				style={{
					display: collapsedItems.includes(i) ? 'none' : 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Date de début{' '}
							<span style={{ color: 'red' }}>*</span>
						</span>
					</div>
					<Controller
						name={`missions[${i}].date_debut`}
						rules={{ required: true }}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Flatpickr
								onChange={([date]) => {
									const resulta = handleDateDebutChange(date)
									onChange(resulta.date_debut)

									const endTimeOptions = filterEndTimeOptions(
										resulta.heure_debut,
										resulta.date_debut
									)
									if (endTimeOptions.length > 0) {
										const firstEndTime =
											endTimeOptions[0].value
										const newEndDate = calculateEndDate(
											resulta.date_debut,
											resulta.heure_debut,
											firstEndTime
										)
										setValue(
											`missions[${i}].date_fin`,
											newEndDate
										)
										setValue(
											`missions[${i}].heure_fin`,
											firstEndTime
										)
									}
								}}
								className='date-input'
								options={{
									mode: 'single',
									locale: French,
									minDate: moment().startOf('day').toDate(),
									dateFormat: 'd/m/Y',
								}}
								value={value}
							/>
						)}
					/>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].date_debut && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner la date de début
							</div>
						)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								position: 'relative',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Heure de début{' '}
								<span style={{ color: 'red' }}>*</span>
							</span>
						</div>
						<Controller
							name={`missions[${i}].heure_debut`}
							rules={{ required: true }}
							control={control}
							render={({ field: { onChange, value } }) => (
								<select
									value={value || ''}
									disabled={display}
									onChange={(e) => {
										onChange(e.target.value)
										const endTimeOptions =
											filterEndTimeOptions(
												e.target.value,
												watch(
													`missions[${i}].date_debut`
												)
											)
										if (endTimeOptions.length > 0) {
											const firstEndTime =
												endTimeOptions[0].value
											const newEndDate = calculateEndDate(
												watch(
													`missions[${i}].date_debut`
												),
												e.target.value,
												firstEndTime
											)
											setValue(
												`missions[${i}].heure_fin`,
												firstEndTime
											)
											setValue(
												`missions[${i}].date_fin`,
												newEndDate
											)
										}
									}}
									style={{
										padding: '1rem',
										borderRadius: '0.5rem',
										color: '#858ea8',
										border: 'none',
										fontFamily: 'Montserrat',
										flex: '1',
										outline: 'none',
									}}
								>
									{generateTimeOptions(
										watch(`missions[${i}].date_debut`),
										temporisation
									).map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</select>
							)}
						/>
						{errors &&
							errors.missions &&
							errors.missions[i] &&
							errors.missions[i].heure_debut && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez remplir l'heure de début
								</div>
							)}
					</div>
					<div
						style={{
							width: '1rem',
						}}
					/>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								position: 'relative',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Heure de fin{' '}
								<span style={{ color: 'red' }}>*</span>
							</span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								style={{
									width: 20,
									height: 20,

									marginLeft: 10,
								}}
								fill='none'
								viewBox='0 0 24 24'
								stroke='#858ea8'
								onClick={() => {
									setShowInfoHeureFin(true)
								}}
								onMouseLeave={() => {
									setShowInfoHeureFin(false)
								}}
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth={2}
									d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
							{showInfoHeureFin && (
								<div
									style={{
										position: 'absolute',
										top: '20px',
										left: '150px',
										padding: '0.5rem',
										border: '1px solid #ccc',
										borderRadius: '0.5rem',
										background: '#FFFFFF',
										boxShadow:
											'0px 2px 5px rgba(0, 0, 0, 0.1)',
										zIndex: 1000,
										width: 'calc(100% - 30px)',
									}}
								>
									<p
										style={{
											color: '#333',
											fontSize: '14px',
											marginBottom: '0.5rem',
										}}
									>
										L'heure de fin doit se situer entre 7 et
										12 heures après l'heure de début.
									</p>
								</div>
							)}
						</div>
						<Controller
							name={`missions[${i}].heure_fin`}
							rules={{ required: true }}
							control={control}
							render={({ field: { onChange, value } }) => (
								<select
									value={value || ''}
									disabled={display}
									onChange={(e) => {
										onChange(e.target.value)
										const newEndDate = calculateEndDate(
											watch(`missions[${i}].date_debut`),
											watch(`missions[${i}].heure_debut`),
											e.target.value
										)
										setValue(
											`missions[${i}].date_fin`,
											newEndDate
										)
									}}
									style={{
										padding: '1rem',
										borderRadius: '0.5rem',
										color: '#858ea8',
										border: 'none',
										fontFamily: 'Montserrat',
										flex: '1',
										outline: 'none',
									}}
								>
									{filterEndTimeOptions(
										watch(`missions[${i}].heure_debut`),
										watch(`missions[${i}].date_debut`)
									).map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</select>
							)}
						/>
						{errors &&
							errors.missions &&
							errors.missions[i] &&
							errors.missions[i].heure_fin && (
								<div
									style={{
										color: 'red',
										marginTop: '0.5rem',
									}}
								>
									Veuillez remplir l'heure de fin
								</div>
							)}
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Date de Fin <span style={{ color: 'red' }}>*</span>
						</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							style={{
								width: 20,
								height: 20,

								marginLeft: 10,
							}}
							fill='none'
							viewBox='0 0 24 24'
							stroke='#858ea8'
							onClick={() => {
								setShowInfoDateFin(true)
							}}
							onMouseLeave={() => {
								setShowInfoDateFin(false)
							}}
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						</svg>
						{showInfoDateFin && (
							<div
								style={{
									position: 'absolute',
									top: '20px',
									left: '150px',
									padding: '0.5rem',
									border: '1px solid #ccc',
									borderRadius: '0.5rem',
									background: '#FFFFFF',
									boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
									zIndex: 1000,
									width: 'calc(100% - 30px)',
								}}
							>
								<p
									style={{
										color: '#333',
										fontSize: '14px',
										marginBottom: '0.5rem',
									}}
								>
									La date de fin est calculée automatiquement
								</p>
							</div>
						)}
					</div>
					<Controller
						name={`missions[${i}].date_fin`}
						rules={{ required: true }}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Flatpickr
								disabled={true}
								onChange={([date]) => {
									onChange(date)
								}}
								className='date-input'
								options={{
									mode: 'single',
									locale: French,
									minDate: moment().startOf('day').toDate(),
									dateFormat: 'd/m/Y',
								}}
								value={value}
								style={{
									backgroundColor: '#d3d3d3',
									opacity: 0.6,
									cursor: 'not-allowed',
								}}
							/>
						)}
					/>

					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].date_fin && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner la date de fin
							</div>
						)}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Nombre de kilomètre{' '}
						<span style={{ color: 'red' }}>*</span>
					</span>

					<div
						style={{
							position: 'relative',
							width: '100%',
						}}
					>
						<Select
							disabled={display}
							{...register(`missions[${i}].kilometrage`, {
								required: true,
							})}
							defaultValue=''
							onChange={(e) => {
								setValue(
									`missions[${i}].kilometrage`,
									e.target.value
								)
								setDistanceVector(
									new Map(
										distanceVector.set(
											`${i}`,
											e.target.value * 1000
										)
									)
								)
							}}
						>
							<option value='' disabled selected>
								Selectionner
							</option>
							<option value='200'>200 </option>
							<option value='300'>300 </option>
							<option value='400'>400 </option>
						</Select>
						<span
							style={{
								position: 'absolute',
								right: '3rem',
								top: '1rem',
							}}
						>
							KM
						</span>
					</div>

					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].kilometrage && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez remplir le nombre de kilomètres
							</div>
						)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Temps de chargement{' '}
						<span style={{ color: 'red' }}>*</span>
					</span>
					<Controller
						name={`missions[${i}].taux_chargement`}
						rules={{ required: true }}
						control={control}
						defaultValue='10'
						render={({ field: { onChange, value } }) => (
							<select
								value={value || '10'}
								onChange={(e) => onChange(e.target.value)}
								style={{
									padding: '1rem',
									borderRadius: '0.5rem',
									color: '#858ea8',
									border: 'none',
									fontFamily: 'Montserrat',
									flex: '1',
									outline: 'none',
								}}
							>
								<option value='10'>10 min</option>
								<option value='15'>15 min</option>
								<option value='20'>20 min</option>
								<option value='25'>25 min</option>
								<option value='30'>30 min</option>
							</select>
						)}
					/>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].taux_chargement && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner le taux de chargement
							</div>
						)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
						width: '100%',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Notes Utiles
					</span>
					<StyledTextArea
						disabled={display}
						rows={4}
						placeholder=''
						{...register(`missions[${i}].notes`)}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
						width: '50%',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Joindre Documents (PDF)
						</span>

						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							(Facultatif)
						</span>
					</div>

					<UploadFileCommande
						initialFiles={watch(`missions[${i}].files`)}
						name={`file_${i}`}
						onChange={(files) => {
							if (keepFilesAsObjects) {
								setValue(
									`missions[${i}].files`,
									files.map((file) => file)
								)
							} else {
								setValue(
									`missions[${i}].files`,
									files.map((file) => file.id)
								)
							}
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a t-il de la manutention chauffeur ?
						<span style={{ color: 'red' }}>*</span>
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`manutention_chauffeur-oui-${i}`}
								type='radio'
								value={true}
								{...register(
									`missions[${i}].manutention_chauffeur`,
									{
										required: true,
									}
								)}
							/>

							<label htmlFor={`manutention_chauffeur-oui-${i}`}>
								Oui
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`manutention_chauffeur-non-${i}`}
								type='radio'
								value={false}
								{...register(
									`missions[${i}].manutention_chauffeur`,
									{
										required: true,
									}
								)}
							/>

							<label htmlFor={`manutention_chauffeur-non-${i}`}>
								Non
							</label>
						</div>
					</div>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].manutention_chauffeur && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner si la manutention
								chauffeur est Oui ou Non.
							</div>
						)}
				</div>
				{isActiverRippeur && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Nombre de ripper en plus du chauffeur
							</span>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								( Facultatif )
							</span>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '1rem',
							}}
						>
							<div>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='13.605'
									height='27.839'
									viewBox='0 0 13.605 27.839'
									style={{
										marginRight: '.5rem',
									}}
								>
									<g id='user' transform='translate(0.5 0.5)'>
										<path
											id='Path_11624'
											d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
											transform='translate(-25.195 -1.992)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11625'
											d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
											transform='translate(-20.562 -14.552)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11626'
											d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
											transform='translate(-30.081 -21.807)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11627'
											d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
											transform='translate(-24.794 -21.807)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
									</g>
								</svg>
							</div>

							<CustomInput
								width={'100%'}
								type='number'
								disabled={display}
								defaultValue={0}
								min={0}
								max={2}
								{...register(`missions[${i}].nombre_rippeur`, {
									valueAsNumber: true,
								})}
								onBlur={(e) => {
									if (!e.target.value) {
										setValue(
											`missions[${i}].nombre_rippeur`,
											0
										)
									}
								}}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Nombre d'heures des rippeurs
							</span>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '1rem',
							}}
						>
							<CustomInput
								width={'100%'}
								type='text'
								disabled={true}
								{...register(`missions[${i}].heure_rippeur`)}
								style={{
									backgroundColor: '#d3d3d3',
								}}
							/>
						</div>
					</div>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Lieu de livraison{' '}
						<span style={{ color: 'red' }}>*</span>
					</p>
					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`livraison-1-${i}`}
								type='radio'
								disabled={display}
								value='Entrepot/Magasin/Chantier'
								{...register(`missions[${i}].livraison`, {
									required: true,
								})}
							/>

							<label htmlFor={`livraison-1-${i}`}>
								Entrepot/Magasin/Chantier
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`livraison-2-${i}`}
								type='radio'
								disabled={display}
								value='Domicile/Batiment/Maison'
								{...register(`missions[${i}].livraison`, {
									required: true,
								})}
							/>

							<label htmlFor={`livraison-2-${i}`}>
								Domicile/Batiment/Maison
							</label>
						</div>
					</div>

					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].livraison && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner le lieu de livraison.
							</div>
						)}
					<div>
						{livraison.current === 'Domicile/Batiment/Maison' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											marginRight: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Etage
									</span>
									<Select
										style={{ width: '100%' }}
										disabled={display}
										{...register(`missions[${i}].etage`, {
											required: true,
										})}
									>
										<option value='0'>0</option>
										<option
											value='1-10'
											disabled={isEtageDisabled}
										>
											1-10
										</option>
										<option
											value='11-20'
											disabled={isEtageDisabled}
										>
											11-20
										</option>
										<option
											value='21-30'
											disabled={isEtageDisabled}
										>
											21-30
										</option>
									</Select>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<Controller
										name={`missions[${i}].escalier`}
										control={control}
										rules={{
											required:
												!isEtageDisabled &&
												!getValues(
													`missions[${i}].assenceur`
												) === undefined &&
												watch(`missons[${i}.etage]`) !==
													'0',
										}}
										render={({ field }) => (
											<input
												type='checkbox'
												style={{ marginRight: '1rem' }}
												disabled={display}
												checked={field.value === true}
												onChange={(e) =>
													field.onChange(
														e.target.checked
															? true
															: false
													)
												}
											/>
										)}
									/>

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Escalier
									</span>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<Controller
										name={`missions[${i}].assenceur`}
										control={control}
										rules={{
											required:
												!isEtageDisabled &&
												getValues(
													`missions[${i}].escalier`
												) === undefined &&
												watch(
													`missions[${i}].etage`
												) !== '0',
										}}
										render={({ field }) => (
											<input
												type='checkbox'
												style={{ marginRight: '1rem' }}
												disabled={display}
												checked={field.value === true}
												onChange={(e) =>
													field.onChange(
														e.target.checked
															? true
															: false
													)
												}
											/>
										)}
									/>

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Assenceur
									</span>
								</div>
							</div>
						)}
					</div>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].etage && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez entrer le numéro d'étage.
							</div>
						)}
					{((errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].escalier) ||
						(errors &&
							errors.missions &&
							errors.missions[i] &&
							errors.missions[i].assenceur)) && (
						<div style={{ color: 'red', marginTop: '0.5rem' }}>
							Veuillez sélectionner le type d'étage.
						</div>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Nature du chargement{' '}
						<span style={{ color: 'red' }}>*</span>
					</p>
					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`nature-chargement-1-${i}`}
								type='radio'
								disabled={display}
								value='vrac'
								{...register(
									`missions[${i}].nature_chargement`,
									{
										required: true,
									}
								)}
							/>

							<label htmlFor={`nature-chargement-1-${i}`}>
								Vrac
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`nature-chargement-2-${i}`}
								type='radio'
								disabled={display}
								value='palette'
								{...register(
									`missions[${i}].nature_chargement`,
									{
										required: true,
									}
								)}
							/>

							<label htmlFor={`nature-chargement-2-${i}`}>
								Palette
							</label>
						</div>
					</div>

					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].nature_chargement && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner la nature du chargement.
							</div>
						)}
					<div>
						{nature_chargement.current === 'palette' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1.5rem',
										width: '100%',
									}}
								>
									<>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Nombre de palettes{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</span>
										<CustomInput
											width={'100%'}
											type='number'
											disabled={display}
											min={0}
											{...register(
												`missions[${i}].nombre_palettes`,
												{ required: true }
											)}
										/>
										{errors &&
											errors.missions &&
											errors.missions[i] &&
											errors.missions[i]
												.nombre_palettes && (
												<div
													style={{
														color: 'red',
														marginTop: '0.5rem',
													}}
												>
													Veuillez fournir le nombre
													de palettes.
												</div>
											)}
									</>
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
										width: '100%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Décrire la nature des palettes{' '}
										<span style={{ color: 'red' }}>*</span>
									</span>
									<StyledTextArea
										disabled={display}
										type='text'
										rows={4}
										placeholder=''
										{...register(
											`missions[${i}].nature_palettes`,
											{ required: true }
										)}
									/>
									{errors &&
										errors.missions &&
										errors.missions[i] &&
										errors.missions[i].nature_palettes && (
											<div
												style={{
													color: 'red',
													marginTop: '0.5rem',
												}}
											>
												Veuillez fournir une description
												pour la nature des palettes.
											</div>
										)}
								</div>
							</div>
						)}
						{nature_chargement.current === 'vrac' && (
							<>
								<div
									style={{
										position: 'relative',
										width: '100%',
									}}
								>
									<CustomInput
										width={'100%'}
										type='number'
										disabled={display}
										required={true}
										min={0}
										{...register(
											`missions[${i}].nombre_dimension`
										)}
									/>
									<span
										style={{
											position: 'absolute',
											right: '3rem',
											top: '1rem',
										}}
									>
										M³
									</span>
								</div>
								{errors &&
									errors.missions &&
									errors.missions[i] &&
									errors.missions[i].nombre_dimension && (
										<div
											style={{
												color: 'red',
												marginTop: '0.5rem',
											}}
										>
											Veuillez fournir le nombre de
											dimension.
										</div>
									)}
							</>
						)}
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						marginBottom: '2.5rem',
					}}
				>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Type de transport{' '}
						<span style={{ color: 'red' }}>*</span>
					</p>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '1rem',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginBottom: '1rem',
							}}
						>
							<div
								style={{
									width: '15rem',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								<input
									type='radio'
									disabled={display}
									style={{
										marginRight: '1rem',
									}}
									{...register(
										`missions[${i}].type_transport`,
										{
											required: true,
										}
									)}
									value={'Véhicule léger'}
								/>

								<span
									style={{
										marginRight: '0.5rem',
									}}
								>
									Véhicule léger
								</span>
								<img
									src={VehiculeLeger}
									style={{
										width: 40,
									}}
									alt=''
								/>
							</div>

							<div
								style={{
									width: '15rem',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								<input
									style={{
										marginRight: '1rem',
									}}
									disabled={display}
									type='radio'
									{...register(
										`missions[${i}].type_transport`,
										{
											required: true,
										}
									)}
									value={'Poids lourds'}
								/>

								<span
									style={{
										marginRight: '0.5rem',
									}}
								>
									Poids lourds
								</span>

								<img
									src={PoidsLours}
									style={{
										width: 40,
									}}
									alt=''
								/>
							</div>
						</div>
					</div>
					{type_transport.current && (
						<>
							<Seperator />

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									//marginBottom: '1rem',
								}}
							>
								{type_transport.current !== 'Poids lourds' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
										}}
									>
										<div style={{ width: '100%' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
													width: '100%',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',

														display: 'flex',

														width: '48%',
														marginRight: '2%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={Break}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isBreakDisabled
															}
															{...register(
																`missions[${i}].type_transport_details`,
																{
																	required: true,
																}
															)}
															value='Break'
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Break
														</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontWeight:
																	'bold',
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
															}}
														>
															1097 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															6M3{' '}
														</span>
														<span>
															Longueur utile :
															3750mm{' '}
														</span>
														<span>
															Largeur utile :
															1956mm{' '}
														</span>
														<span>
															Hauteur utile :
															1320mm{' '}
														</span>
														<span>
															Charge utiles :
															1097kg{' '}
														</span>
														<span>
															Nombre de palettes:
															2 pal europe{' '}
														</span>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={Fourgon12}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isF12Disabled
															}
															{...register(
																`missions[${i}].type_transport_details`,
																{
																	required: true,
																}
															)}
															value='Fourgon 12m³'
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Fourgon 12m³
														</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
																fontWeight:
																	'bold',
															}}
														>
															1244 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															12M3{' '}
														</span>
														<span>
															Longueur utile :
															3030mm{' '}
														</span>
														<span>
															Largeur utile :
															2070mm{' '}
														</span>
														<span>
															Hauteur utile :
															1320mm{' '}
														</span>
														<span>
															Charge utiles :
															1244kg{' '}
														</span>
														<span>
															Nombre de palettes:
															5 pal europe
														</span>
													</div>
												</div>
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
													width: '100%',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
														marginRight: '2%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={Fourgon14}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isF14Disabled
															}
															{...register(
																`missions[${i}].type_transport_details`,
																{
																	required: true,
																}
															)}
															value='Fourgon 14m³'
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Fourgon 14m³
														</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																fontWeight:
																	'bold',
																marginRight:
																	'0.5rem',
															}}
														>
															1353 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															14M3{' '}
														</span>
														<span>
															Longueur utile :
															3680mm{' '}
														</span>
														<span>
															Largeur utile :
															2070mm{' '}
														</span>
														<span>
															Hauteur utile :
															1820mm{' '}
														</span>
														<span>
															Charge utiles :
															1353kg{' '}
														</span>
														<span>
															Nombre de palettes:
															6 pal europe
														</span>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={vehiculeCamion}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'110px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isC20Disabled
															}
															{...register(
																`missions[${i}].type_transport_details`,
																{
																	required: true,
																}
															)}
															value='Camion 20m³'
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Camion 20m³
														</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
																fontWeight:
																	'bold',
															}}
														>
															1200 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															20M3{' '}
														</span>
														<span>
															Longueur utile :
															4320mm{' '}
														</span>
														<span>
															Largeur utile :
															2070mm{' '}
														</span>
														<span>
															Hauteur utile :
															2100mm{' '}
														</span>
														<span>
															Charge utiles :
															1200kg{' '}
														</span>
														<span>
															Nombre de palettes:
															7 pal europe
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>

							{type_transport.current !== 'Poids lourds' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Options Véhicule léger
									</span>

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													{...register(
														`missions[${i}].options_vehicule_leger`,
														{
															required: true,
														}
													)}
													value={'Frigorifique'}
													onChange={(e) => {
														const isChecked =
															e.target.checked
														const updatedOptions =
															isChecked
																? [
																		...optionsVehiculeLegerValue,
																		'Frigorifique',
																	]
																: optionsVehiculeLegerValue.filter(
																		(
																			option
																		) =>
																			option !==
																			'Frigorifique'
																	)
														const bacheeIndex =
															updatedOptions.indexOf(
																'Bachée'
															)
														if (
															bacheeIndex !== -1
														) {
															updatedOptions.splice(
																bacheeIndex,
																1
															)
														}
														setValue(
															`missions[${i}].options_vehicule_leger`,
															updatedOptions
														)
														handleOptionChange(e)
													}}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Frigorifique
												</span>
											</div>
											{type_transport_details ===
												'Camion 20m³' && (
												<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														disabled={display}
														{...register(
															`missions[${i}].options_vehicule_leger`,
															{
																required: true,
															}
														)}
														value={'Bachée'}
														onChange={(e) => {
															const isChecked =
																e.target.checked
															const updatedOptions =
																isChecked
																	? [
																			...optionsVehiculeLegerValue,
																			'Bachée',
																		]
																	: optionsVehiculeLegerValue.filter(
																			(
																				option
																			) =>
																				option !==
																				'Bachée'
																		)
															const frigorifiqueIndex =
																updatedOptions.indexOf(
																	'Frigorifique'
																)

															if (
																frigorifiqueIndex !==
																-1
															) {
																updatedOptions.splice(
																	frigorifiqueIndex,
																	1
																)
															}
															setValue(
																`missions[${i}].options_vehicule_leger`,
																updatedOptions
															)
															handleOptionChange(
																e
															)
														}}
													/>

													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Bachée
													</span>
												</div>
											)}
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													{...register(
														`missions[${i}].options_vehicule_leger`,
														{
															required: true,
														}
													)}
													value={'Tall'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Tall
												</span>
											</div>

											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													{...register(
														`missions[${i}].options_vehicule_leger`,
														{
															required: true,
														}
													)}
													value={'Hayon'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Hayon
												</span>
											</div>
										</div>

										{type_transport.current == null && (
											<Seperator />
										)}
									</div>
								</div>
							)}

							{type_transport.current !== 'Véhicule léger' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
									}}
								>
									<div style={{ width: '100%' }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
													width: '100%',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
														marginRight: '2%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={tonnes7}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isV75TDisabled
															}
															{...register(
																`missions[${i}].vehicule_poids_lourds`,
																{
																	required: true,
																}
															)}
															value='7,5 Tonnes'
														/>

														<span>7,5 Tonnes</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
																fontWeight:
																	'bold',
															}}
														>
															2470 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															30M3{' '}
														</span>
														<span>
															Longueur utile :
															6575mm{' '}
														</span>
														<span>
															Largeur utile :
															2045mm{' '}
														</span>
														<span>
															Hauteur utile :
															2400mm{' '}
														</span>
														<span>
															Charge utiles :
															2470kg{' '}
														</span>
														<span>
															Nombre de palettes:
															16 pal europe
														</span>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
														marginTop: -5,
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={tonnes12}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																is12TDisabled
															}
															{...register(
																`missions[${i}].vehicule_poids_lourds`,
																{
																	required: true,
																}
															)}
															value='12 Tonnes'
														/>

														<span>12 Tonnes</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
																fontWeight:
																	'bold',
															}}
														>
															5600 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															45M3{' '}
														</span>
														<span>
															Longueur utile :
															7540mm{' '}
														</span>
														<span>
															Largeur utile :
															2480mm{' '}
														</span>
														<span>
															Hauteur utile :
															2500mm{' '}
														</span>
														<span>
															Charge utiles :
															5600kg{' '}
														</span>
														<span>
															Nombre de palettes:
															19 pal europe
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div style={{ width: '100%' }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
													width: '100%',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
														marginRight: '2%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={tonnes19}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																is19TDisabled
															}
															{...register(
																`missions[${i}].vehicule_poids_lourds`,
																{
																	required: true,
																}
															)}
															value='19 Tonnes'
														/>

														<span>19 Tonnes</span>
													</div>
													<div
														style={{
															marginLeft: 36,
														}}
													>
														<span
															style={{
																fontSize:
																	'14px',
																marginRight:
																	'0.5rem',
																fontWeight:
																	'bold',
															}}
														>
															9200 kg
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Dimension en M3 :
															58M3{' '}
														</span>
														<span>
															Longueur utile :
															9100mm{' '}
														</span>
														<span>
															Largeur utile :
															2470mm{' '}
														</span>
														<span>
															Hauteur utile :
															2490mm{' '}
														</span>
														<span>
															Charge utiles :
															9200kg{' '}
														</span>
														<span>
															Nombre de palettes:
															22 pal europe
														</span>
													</div>
												</div>
												{watch('type_commande') !==
													'course-a-course' && (
													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															width: '48%',
														}}
													>
														<div
															style={{
																width: '100%',
																height: 'auto',
																marginBottom:
																	'0.5rem',
															}}
														>
															<img
																src={SemiImg}
																style={{
																	width: '100%',
																	height: 'auto',
																	maxWidth:
																		'140px',
																	maxHeight:
																		'80px',
																	objectFit:
																		'cover',
																}}
																alt=''
															/>
														</div>
														<div
															style={{
																width: '50%',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
																marginTop: -20,
															}}
														>
															<input
																type='radio'
																style={{
																	marginRight:
																		'1rem',
																}}
																disabled={semi}
																{...register(
																	`missions[${i}].vehicule_poids_lourds`,
																	{
																		required: true,
																	}
																)}
																value='Semi'
															/>

															<span>Semi</span>
														</div>
														<div
															style={{
																marginLeft: 36,
															}}
														>
															<span
																style={{
																	fontSize:
																		'14px',
																	marginRight:
																		'0.5rem',
																	fontWeight:
																		'bold',
																}}
															>
																27000 kg
															</span>
														</div>

														<div
															style={{
																display: 'flex',
																flexDirection:
																	'column',
																background:
																	'#f2f3f4',
																padding: 10,
																width: '70%',
																borderRadius:
																	'5px',
																marginTop:
																	'10px',
																fontSize:
																	'13px',
																lineHeight:
																	'1.5',
															}}
														>
															<span>
																Dimension en M3
																: 86M3{' '}
															</span>
															<span>
																Longueur
																utile:13500mm
															</span>
															<span>
																Largeur utile :
																2470mm{' '}
															</span>
															<span>
																Hauteur utile :
																2590mm{' '}
															</span>
															<span>
																Charge utiles :
																27000kg{' '}
															</span>
															<span>
																Nombre de
																palettes: 33 pal
																europe
															</span>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>

									<div style={{ width: '100%' }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
													width: '100%',
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
														marginRight: '2%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={Semi}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'120px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={
																isTracteurSemiDisabled
															}
															{...register(
																`missions[${i}].vehicule_poids_lourds`,
																{
																	required: true,
																}
															)}
															value='Tracteur+semi'
														/>

														<span>
															Tracteur+semi
														</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<span>
															Longueur utile :
															2.45 M
														</span>
														<span>
															Largeur utile : 1.50
															M
														</span>
														<span>
															Hauteur utile : 1.60
															M
														</span>
														<span>
															Volume utile : 6 M3
														</span>
														<span>
															Charge max : 1000 kg
														</span>
														<span>
															Nombre de palettes:
															32 pal europe
														</span>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '48%',
													}}
												>
													<div
														style={{
															width: '100%',
															height: 'auto',
															marginBottom:
																'0.5rem',
														}}
													>
														<img
															src={tracteurSemi}
															style={{
																width: '100%',
																height: 'auto',
																maxWidth:
																	'100px',
																maxHeight:
																	'80px',
																objectFit:
																	'cover',
															}}
															alt=''
														/>
													</div>

													<div
														style={{
															width: '50%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='radio'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={display}
															{...register(
																`missions[${i}].vehicule_poids_lourds`,
																{
																	required: true,
																}
															)}
															value='Tracteur'
														/>

														<span>Tracteur</span>
													</div>

													<div
														style={{
															display: 'flex',
															flexDirection:
																'column',
															background:
																'#f2f3f4',
															padding: 10,
															width: '70%',
															borderRadius: '5px',
															marginTop: '10px',
															fontSize: '13px',
															lineHeight: '1.5',
														}}
													>
														<div
															style={{
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<span>
																Longueur utile :{' '}
															</span>
															<span>2.45 M</span>
														</div>
														<div
															style={{
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<span>
																Largeur utile :{' '}
															</span>
															<span>1.50 M</span>
														</div>
														<div
															style={{
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<span>
																Hauteur utile :{' '}
															</span>
															<span>1.60 M</span>
														</div>
														<div
															style={{
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<span>
																Volume utile :{' '}
															</span>
															<span>6 M3</span>
														</div>
														<div
															style={{
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<span>
																Charge max :{' '}
															</span>
															<span>1000 kg</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options poids lourds
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
												}}
											>
												<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														disabled={display}
														{...register(
															`missions[${i}].options_poids_lourds`,
															{
																required: true,
															}
														)}
														value={'Frigorifique'}
														onChange={(e) => {
															const isChecked =
																e.target.checked
															const updatedOptions =
																isChecked
																	? [
																			...optionsPoidsLourdsValue,
																			'Frigorifique',
																		]
																	: optionsPoidsLourdsValue?.filter(
																			(
																				option
																			) =>
																				option !==
																				'Frigorifique'
																		)
															const bacheeIndex =
																updatedOptions.indexOf(
																	'Bachée'
																)
															if (
																bacheeIndex !==
																-1
															) {
																updatedOptions.splice(
																	bacheeIndex,
																	1
																)
															}
															setValue(
																`missions[${i}].options_poids_lourds`,
																updatedOptions
															)
															handleOptionChange(
																e
															)
														}}
													/>

													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Frigorifique
													</span>
												</div>
												{type_transport_details_poids_lourds !==
													'Tracteur' && (
													<div
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={display}
															{...register(
																`missions[${i}].options_poids_lourds`,
																{
																	required: true,
																}
															)}
															value={'Bachée'}
															onChange={(e) => {
																const isChecked =
																	e.target
																		.checked
																const updatedOptions =
																	isChecked
																		? [
																				...optionsPoidsLourdsValue,
																				'Bachée',
																			]
																		: optionsPoidsLourdsValue.filter(
																				(
																					option
																				) =>
																					option !==
																					'Bachée'
																			)
																const frigorifiqueIndex =
																	updatedOptions.indexOf(
																		'Frigorifique'
																	)

																if (
																	frigorifiqueIndex !==
																	-1
																) {
																	updatedOptions.splice(
																		frigorifiqueIndex,
																		1
																	)
																}
																setValue(
																	`missions[${i}].options_poids_lourds`,
																	updatedOptions
																)
																handleOptionChange(
																	e
																)
															}}
														/>

														<span
															style={{
																marginRight:
																	'0.5rem',
															}}
														>
															Bachée
														</span>
													</div>
												)}
												<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														disabled={display}
														{...register(
															`missions[${i}].options_poids_lourds`,
															{
																required: true,
															}
														)}
														value={'Tall'}
													/>

													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Tall
													</span>
												</div>

												<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='checkbox'
														style={{
															marginRight: '1rem',
														}}
														disabled={display}
														{...register(
															`missions[${i}].options_poids_lourds`,
															{
																required: true,
															}
														)}
														value={'Hayon'}
													/>

													<span
														style={{
															marginRight:
																'0.5rem',
														}}
													>
														Hayon
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</>
					)}
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].type_transport && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner le type de trasport .
							</div>
						)}
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].type_transport_details && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez choisir le type de transport détaillé.
							</div>
						)}
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].vehicule_poids_lourds && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez choisir le type de transport détaillé.
							</div>
						)}
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						(errors.missions[i].options_vehicule_leger ||
							errors.missions[i].options_poids_lourds) && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez cocher au moins une option pour le type
								de transport.
							</div>
						)}
					{/* {errors && */}
					{/* 	errors.missions && */}
					{/* 	errors.missions[i] && */}
					{/* 	errors.missions[i].options_poids_lourd && ( */}
					{/* 		<div style={{ color: 'red', marginTop: '0.5rem' }}> */}
					{/* 			Veuillez cocher au moins une option pour le type */}
					{/* 			de transport. */}
					{/* 		</div> */}
					{/* 	)} */}
				</div>
				<div>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a-t-il un transport de matières dangereuses ?{' '}
						<span style={{ color: 'red' }}>*</span>
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`danger-oui-${i}`}
								type='radio'
								disabled={display}
								value={true}
								{...register(`missions[${i}].danger`, {
									required: true,
								})}
							/>

							<label htmlFor={`danger-oui-${i}`}>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`danger-non-${i}`}
								type='radio'
								disabled={display}
								value={false}
								{...register(`missions[${i}].danger`, {
									required: true,
								})}
							/>

							<label htmlFor={`danger-non-${i}`}>Non</label>
						</div>
					</div>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].danger && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner si le transport de
								matières dangereuses est Oui ou Non.
							</div>
						)}
				</div>
				<div>
					<p
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}
					>
						La plateforme prend en charge jusqu'a 100 000 euros de
						valeur marchandise.
					</p>

					<p
						style={{
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						La valeur de votre chargement dépasse t'elle 100 000
						euros?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`assurance-oui-${i}`}
								type='radio'
								disabled={display}
								value={true}
								{...register(`missions[${i}].assurance`, {
									required: true,
								})}
							/>

							<label htmlFor={`assurance-oui-${i}`}>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`assurance-non-${i}`}
								type='radio'
								disabled={display}
								value={false}
								{...register(`missions[${i}].assurance`, {
									required: true,
								})}
							/>

							<label htmlFor={`assurance-non-${i}`}>Non</label>
						</div>
					</div>
					{errors &&
						errors.missions &&
						errors.missions[i] &&
						errors.missions[i].assurance && (
							<div style={{ color: 'red', marginTop: '0.5rem' }}>
								Veuillez sélectionner une option pour
								l'assurance.
							</div>
						)}
					<div>
						{assurance.current === 'true' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										//width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											color: 'black',
											fontWeight: 'bold',
											marginBottom: '1rem',
										}}
									>
										Ci-dessous saisir la valeur du
										chargement afin que nous puissions
										avertir les assureurs
									</span>

									<div
										style={{
											position: 'relative',
											width: '100%',
										}}
									>
										<CustomInput
											width={'100%'}
											type='text'
											disabled={display}
											{...register(
												`missions[${i}].assurance_amount`,
												{
													validate: (value) => {
														const numericValue =
															Number(
																value.replace(
																	/\s+/g,
																	''
																)
															)
														const onlyDigits =
															/^\d+$/
														if (!value) {
															return "Le montant de l'assurance est requis."
														} else if (
															isNaN(
																numericValue
															) ||
															!onlyDigits.test(
																value
															)
														) {
															return 'Veuillez entrer une valeur numérique valide.'
														} else if (
															numericValue <= 0
														) {
															return 'Le montant doit être supérieur à zéro.'
														}
														return true
													},
													required:
														"Le montant de l'assurance est requis.",
												}
											)}
										/>
										<span
											style={{
												position: 'absolute',
												right: '3rem',
												top: '1rem',
												color: 'red',
											}}
										>
											€
										</span>
									</div>
								</div>

								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Vous avez la possiblité de ne pas saisir la
									valur du chargment, cependant nous
									retiendrons la valeur de 100 000 euros. Cela
									conformément au conditions d'utilisation de
									notre plateforme.
								</p>
								{errors &&
									errors.missions &&
									errors.missions[i] &&
									errors.missions[i].assurance_amount && (
										<div style={{ color: 'red' }}>
											{
												errors.missions[i]
													.assurance_amount.message
											}
										</div>
									)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default MissionsMiseADispo
